const TOKEN = "iot_token";
const USERNAME = "iot_username";
const AUTH = "iot_auth";
const USER_TYPE = "iot_userType";
const CURRENT_ORG_ID = "iot_currentOrgId";
const TENANT_ADMIN = "iot_TenantAdmin";
const IM_USERNAME = "iot_imUsername";
const IM_PASSWORD = "iot_imPassword";
const UI_SETTING = "iot_uiSetting";
const PLATFORM = "iot_platform";
const CAPTCHA = "step_captcha";
const storage = window.localStorage;
export default {
  login(obj) {
    storage.setItem(TOKEN, obj.token);
    storage.setItem(USERNAME, obj.username);
    storage.setItem(AUTH, obj.auth);
    storage.setItem(USER_TYPE, obj.userType);
    storage.setItem(CURRENT_ORG_ID, obj.orgId);
    storage.setItem(IM_USERNAME, obj.imUsername);
    storage.setItem(IM_PASSWORD, obj.imPassword);
    storage.setItem(UI_SETTING, JSON.stringify(obj.uiSetting));
    storage.setItem(TENANT_ADMIN, obj.isTenantAdmin);
    storage.setItem(PLATFORM, obj.platform);
  },

  logout() {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key !== "local") {
        localStorage.removeItem(key);
      }
    });
  },

  isShowCaptcha() {
    return storage.getItem(CAPTCHA) === "true";
  },

  showCaptcha() {
    storage.setItem(CAPTCHA, "true");
  },

  hideCaptcha() {
    storage.setItem(CAPTCHA, "false");
  },

  isLogin() {
    return !!this.getToken();
  },

  getToken() {
    return storage.getItem(TOKEN);
  },

  getUsername() {
    return storage.getItem(USERNAME);
  },

  getAuth() {
    return storage.getItem(AUTH);
  },

  getUserType() {
    return parseInt(storage.getItem(USER_TYPE));
  },

  setCurrentOrgId(orgId) {
    storage.setItem(CURRENT_ORG_ID, orgId);
  },

  getCurrentOrgId() {
    return storage.getItem(CURRENT_ORG_ID);
  },
  getTenantAdmin() {
    return storage.getItem(TENANT_ADMIN);
  },
  getImUsername() {
    return storage.getItem(IM_USERNAME);
  },
  getPlatform() {
    return storage.getItem(PLATFORM);
  },
  getImPassword() {
    return storage.getItem(IM_PASSWORD);
  },

  getUiSetting() {
    try {
      return JSON.parse(storage.getItem(UI_SETTING)) || {};
    } catch (e) {
      return {};
    }
  },
};
