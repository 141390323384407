<!--带有分页逻辑的table-->
<template>
  <div class="vm-page-table">
    <div id="vm-table-bar">
      <div v-if="$slots.toolbar" class="tools-bar">
        <slot name="toolbar"></slot>
      </div>
      <div v-if="$slots.adSearch" ref="adSearch" class="ad-search">
        <div :id="'vm-search-' + _uid" style="width: 100%">
          <slot name="adSearch"></slot>
          <div style="display: inline-block;">
            <el-button class="searchBtn" :disabled="status === 'loading'" @click="handleSearchClick">
              {{ $l("elevatorNetApply.search", "查询") }}
            </el-button>
            <el-button class="resetBtn" v-if="!hideClear" plain :disabled="status === 'loading'"
              @click="handleClearClick">
              {{ $l("elevatorNetApply.reset", "重置") }}
            </el-button>
          </div>
          <slot name="adSearch-expand"></slot>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <el-table v-loading="status === 'loading'" border ref="elTable" :data="tableData" :max-height="myMaxHeight" :row-key="getRowKeys" v-bind="$attrs"
        v-on="$listeners" :row-class-name="tableRowClassName">
        <template slot="empty">
          <!-- <i v-if="status === 'loading'" class="el-icon-loading" style="font-size: 18px"></i> -->
          <el-button v-if="status === 'error'" @click="handleSearchClick">
            {{ $l("common.loadingFailed", "加载失败，点击刷新") }}
          </el-button>
          <el-button v-else-if="status === 'empty'" @click="handleSearchClick">
            {{ $l("common.noData", "暂无数据，点击刷新") }}
          </el-button>
        </template>
        <el-table-column v-if="multiSelection" :reserve-selection="reserveSelection" type="selection" width="45"
          align="center"></el-table-column>
        <el-table-column v-if="showRadio" align="center" width="50">
          <template slot-scope="scope">
            <el-checkbox :value="scope.$index === currentRowIndex"
              @change="onCheckedChange($event, scope.$index)"></el-checkbox>
          </template>
        </el-table-column>
        <slot></slot>
      </el-table>
      <div style="height: 56px;display: flex;align-items: center;justify-content: flex-end;margin-right: 19px;">
        <el-pagination v-if="paginationStatus" background :current-page="currentPage" :total="total" :page-size="pageSize"
          class="page" :page-sizes="[20, 30, 50, 100, 200]" layout="sizes, total, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>
    <!-- <el-button v-if="status === 'loading'" type="warning" icon="el-icon-loading" class="loading">
      {{ $l("common.loading", "加载中") }}
    </el-button> -->
  </div>
</template>

<script>
/*查询条件filter必须使用:filter.sync="filter"传递*/
export default {
  components: {},
  props: {
    url: {
      type: String,
      required: true,
    },
    filter: {
      type: Object,
    },
    filterFun: {
      type: Function,
    },
    //是否显示清空按钮
    hideClear: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    multiSelection: {
      type: Boolean,
      default: false,
    },
    showRadio: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Boolean,
      default: false,
    },
    paginationStatus: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    //多选时切换页面记住选项
    reserveSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: "loading",//loading error empty
      getListType: 1,//1：自动获取，2查询获取，3重置获取
      currentPage: 1,
      total: 0,
      pages: 0,
      tableData: [],
      //保存一份filter的原始拷贝，用于重置条件
      filterCopy: JSON.stringify(this.filter),
      currentRowIndex: -1,
      myMaxHeight: 0,
      parentName: "",
    };
  },
  mounted() {
    this.parentName = this.$parent.$options.name;
    this.changeMaxHeight();
    window.onresize = () => {
      this.changeMaxHeight();
    };
    //为所有input框添加enter键搜索事件
    let searchBox = document.getElementById("vm-search-" + this._uid);
    if (searchBox) {
      let controls = searchBox.getElementsByTagName("input");
      for (let item of controls) {
        item.onkeydown = event => {
          if (event.key === "Enter") {
            this.getList(1);
          }
        };
      }
    }
  },
  methods: {
    //等于-1表示获取当前页
    getList(pageNo) {
      this.status = "loading";
      if (pageNo > 0) {
        this.currentPage = pageNo;
      }
      let filter = this.filter;

      if (this.filterFun) {
        let filterCopy = JSON.parse(JSON.stringify(filter));
        filter = this.filterFun(filterCopy);
      }
      let params = Object.assign({ pageSize: this.pageSize, pageNo: this.currentPage }, filter);
      let http = this.post ? this.$http.post(this.url, params) : this.$http.get(this.url, params);
      http.then(res => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.pages = res.data.pages;
        this.$emit("get-table-data",this.tableData);
        if (this.total === 0) {
          this.status = "empty";
        } else {
          this.status = "finish";
        }
      }).catch(() => {
        this.status = "error";
      });
    },
    handleCurrentChange(pageNo) {
      this.getList(pageNo);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getList(1);
    },
    handleSearchClick() {
      this.$emit("search-click");
      this.getList(1);
    },
    handleClearClick() {
      this.$emit("update:filter", JSON.parse(this.filterCopy));
      this.$emit("clear");
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    clear() {
      this.tableData = [];
      this.total = 0;
    },
    setCurrentRow(row) {
      this.$refs.elTable.setCurrentRow(row);
    },
    onCheckedChange(val, index) {
      this.currentRowIndex = val ? index : -1;
      this.$emit("radio-change", this.tableData[this.currentRowIndex], val);
    },
    getCurrentRow() {
      return this.tableData[this.currentRowIndex];
    },
    changeMaxHeight() {
      if (this.maxHeight) {
        this.myMaxHeight = this.maxHeight;
      } else {
        let bar = document.getElementById("vm-table-bar");
        this.barHeight = bar.offsetHeight;
        let clientHeight = document.body.clientHeight;
        if (this.parentName === "ElDialog") {
          this.myMaxHeight = clientHeight - this.barHeight - 220;
        } else {
          this.myMaxHeight = clientHeight - this.barHeight - 155;
        }
      }

      // 电梯信息详情
      if(this.type === "elTabs") {
        this.myMaxHeight -= 180;
      } else if(this.type === "elTabsBtn") {
        this.myMaxHeight -= 220;
      }

      this.$nextTick(() => {
        this.$refs.elTable.$el.style["max-height"] = this.myMaxHeight + "px";
      });
    },
    getRowKeys(row) {
      return row.id;
    },

    // 改版
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "highting-row";
      }
    }
  },
};
</script>

<style lang="scss">
.el-table .highting-row {
  background: #F5F8FF;
}

.vm-page-table {
  .tools-bar {
    height: 48px;
    line-height: 48px;

    .el-button {
      padding: 0 20px;
      height: 32px;
      border-radius: 8px;
      font-size: 14px;
      border: none;
      background-color: #fff;

      &.addBtn {
        background-color: #0747FD;
        color: #fff;
      }

      &.greenBtn {
        background-color: #00C291;
        color: #fff;
      }

      &.dangerBtn {
        &:hover {
          color: #FF4B00;
        }
      }
    }
  }

  .ad-search {
    min-height: 64px;
    margin-bottom: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 6px;
    box-sizing: border-box;

    .el-button {
      padding: 0 20px;
      height: 32px;
      border-radius: 8px;
      font-size: 14px;

      &.searchBtn {
        background-color: #0747FD;
        color: #fff;
        border: none;
        margin-left: 24px;
      }

      &.resetBtn {
        &:hover {
          border-color: #e6e6e6;
        }
      }
    }

    .el-input__inner {
      background: #F2F2F2;
      border-radius: 8px 8px 8px 8px;
      border: none;
      height: 32px;
    }

    .el-range-input {
      background: #F2F2F2;
    }
  }

  // .loading {
  //   position: absolute;
  //   margin-left: -40px;
  //   left: 50%;
  //   top: 25%;
  //   z-index: 1000;
  // }

  .el-table {
    border-radius: 12px 12px 0 0;
    tbody {
      td {
        border: none;
      }
    }
  }

  .el-range-editor--mini .el-range-separator {
    line-height: 26px !important;
  }

  .wrapper {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
  }
}
</style>
