export default {
  lang: "it",
  login: {
    loginFailed: "Errore di accesso",
    login: "Di accesso ",
    logout: "Disconnettersi",
    welcome: "Benvenuti",
    account: "Nome utente",
    password: "Password",
    forgotPassword: "Recupera password",
    app: "Scarica l'APP",
    qrCodeApp: "Scansiona per scaricare l'app Elevator Cloud",
    chromeDown: "Scarica l'APP",
    chromeLogin: "Si consiglia di utilizzare il browser chrome per l'accesso",
    phone: "Numero di telefono",
    verify: "CAPTCHA",
    newPassword: "Nuova password",
    submit: "Presentare ",
    sendMSG: "Messaggio di testo",
    resend: "Inviare di nuovo",
    getVer:"Ottieni il codice di verifica",
    emptyVer:"Il codice di verifica non può essere vuoto",
    verSuccess:"L'SMS è stato inviato con successo e sarà valido entro 10 minuti.",
    verFail:"Invio SMS non riuscito",
    verNotRight:"Il codice di verifica è errato o è scaduto",
    tips: {
      account: "Inserire il numero di conto",
      password: "Inserire la password",
      notNull: "Non può essere vuoto",
      logout: "Siete sicuri di esservi disconnessi?",
    },
  },

  // 首页
  home: {
    lifts: "Accessi totali",
    onlineLifts: "Accessi online",
    repairLifts: "Riparazione corrente",
    parts: "Numero totale dei rapporti di apertura giornaliera, settimanale e mensile",
    units: "Unità",
    elevatorStatus: "Stato dell'ascensore",
    faultType: "Tipo di guasto",
    documents: "Documenti allegati",
    events: "Evento in tempo reale",
    faults: "Guasto in tempo reale",
    alarms: "Campanello d'allarme",
    maintenance: "Servizio",
    mainPowerLoss: "Spegnimento del gateway",
    enterStopMode: "Servizio di arresto",
    changePassword: "Cambia password",
    ignore: "Ignora",
    tip: {
      passwordSimple: "Password troppo semplice, è consigliato cambiarla",
    },
    users: "Numero di utenti",
    liftsLocation: "Distribuzione degli ascensori",

    info: "Informazioni Base",
    timeFrame: "Periodo di tempo",
    realTimeInfo: "Informazioni in tempo reale",
    noFaults: "Nessun errore nel ciclo",
  },

  // 公共
  common: {
    new:"Nuovo",
    newLevel:"Nuovo livello",
    selectService:"Seleziona un personale di servizio",
    selectTenant:"Seleziona inquilino",
    keyWord:"Inserisci le parole chiave da filtrare",
    selectType:"Seleziona Tipo",
    AccountStat: "Stato del conto",
    Enable: "Abilitare",
    Disable: "Cancellare",
    AnnualInspection: "Ispezione annuale",
    laddering: "Montacarichi",
    add: "Aggiuntivo",
    search: "Cerca",
    filter: "Ricerca parole chiave",
    pleaseSelect: "Si prega di selezionare",
    operation: "Operazione",
    edit: "Modifica",
    delete: "Cancella",
    isDelete: "Sei sicuro di voler cancellare",
    tips: "Suggerimenti",
    confirm: "Ok",
    cancel: "Cancella",
    tip: {
      deleteSuccess: "Eliminato con successo",
      deleteError: "Cancellazione non riuscita",
      notNull: "Non può essere vuoto",
      getDataError: "Errore nell'acquisizione dei dati",
      saveSuccess: "Salva il successo",
      saveError: "Non riescono a salvare",
      submitError: "Rivedere e inviare",
      baiduMapError: "La mappa di Baidu non è stata caricata",
      googleMapError: "Google Maps potrebbe non caricarsi a causa di problemi di rete",
      needNum: "Inserire un numero maggiore di 0",
      needNum1: "Inserire un numero >=0",
      optional: "Almeno uno dei numeri di fabbrica e il codice dell'impianto di risalita devono essere compilati!",
      completeFloorInformation: "Completare le informazioni sul piano",
      settingSuccess: "Impostazione di successo",
      addSuccess: "Aggiungi con successo",
      addError: "Aggiungi fallimento",
      operationSuccess: "L'operazione è riuscita.",
      operationFailure: "fallimento di un'operazione",

      comSuccess: "Comando inviato con successo",
      lockSuccess: "L'invio del comando di blocco ladder è riuscito",
      setupFailed: "Comando di configurazione fallito",
      setupSucceed: "Comando di impostazione riuscito",
      getDataFailed: "Impossibile ottenere i dati",
      sentSuccess: "Invio del comando di lettura riuscito",
      getSetFailed: "Impossibile ottenere le impostazioni correnti!",
      saveSetSuccess: "Il salvataggio delle impostazioni correnti è riuscito!",
      saveSetFailed: "Impossibile salvare le impostazioni correnti!",
      sentSaveSuccess: "L'invio del comando di salvataggio è riuscito",
      floorNotConfig: "Piano attuale non configurato",
      floorHigher: "Il piano attuale è superiore a 64",
      startCon: "Iniziare a connettersi",
      disCon: "Disconnessione",
      exceptionCon: "Anomalia di connessione",
      startAu: "Inizio dell'accreditamento",
      norCon: "La connessione è normale",

    },
    pleaseEnter: "Si prega di immettere",
    save: "Salva",
    yes: "Si",
    no: "No",
    select: "Opzione",
    to: "Da",
    startDate: "Data di inizio",
    endDate: "Data di fine",
    sevenTime: "Inserite un periodo di tempo entro due mesi!",
    more1: "In più",
    generation: "Generando",
    remark: "Note",
    isDefault: "Predefinito o meno",
    operate: "Operazione",
    index: "Numero di serie",
    remove: "Rimosso",
    createTime: "Tempo di creazione",
    creator: "Compilatore",
    clear: "Azzeramento della selezione",
    confirmSelect: "Clinica",
    status: "Stato dell'autenticazione",
    submit: "Inviato",
    notSubmit: "Non presentato",
    export: "Drivare",
    completed: "Completato",
    notCompleted: "Incompiuto",
    view: "Controllare",
    draft: "Contorno",
    result: "Risultato",
    generateElevatorRecords: "Generare un profilo di sollevamento",
    seconds: " Secondi",
    hide: "Nascondere",
    moveUp: "Spostamento in alto",
    moveDown: "Sposta giù",
    minutes: "Minuti",
    keywordSearch: "Ricerca per parola chiave",
    searchFilter: "Ricerca parole chiave",
    watch: "Guardare",
    advanceSearch: "Ricerche di alto livello",
    pleasePartsSn: "Deve contenere 34 caratteri.",
    chooseAnArea: "Non selezionato",
    detail: "Dettagli",
    setting: "Imposta",
    setBlack: "Assicurarsi che il dispositivo gateway selezionato per l'impostazione sia nella lista nera?",
    cancelBlack: "Assicurarsi che il dispositivo gateway selezionato cancelli la blacklist?",
    settingError: "Errore di impostazione",
    isAdd: "Sei sicuro di volerlo aggiungere?",
    alarm: "Registri di allarme",
    beginTime: "Ora di inizio",
    endTime: "ora di fine",
    confirmTip: "È sicuro di voler eliminare questa riga?",
    more: "Inizio",
    less: "Sistolico",
    set: "Allestimento",
    isGenerate: "Sei sicuro?",
    efficient: "Efficaci",
  },

  yearCheck: {
    yearCheckNo: "Numero di ispezione annuale",
    notice: "Promemoria per l'ispezione annuale",
    expired: "Ispezione annuale scaduta ",
    checkDate: "Data di ispezione",
    date: "Selezionare la data",
    note: "Note",
    file: "Documenti di ispezione annuale",
    rectificationDate: "Data prevista per la rettifica",
    all: "Tutto",
    status: "Risultato dell'ispezione",
    result: {
      pass: "Superato",
      noPass: "Non superato",
    },
    tip: "Selezionare un ascensore",
  },

  // 用户管理
  user: {
    accountUnlock: "Sblocco account bloccato",
    type: "Tipo di utente",
    username: "Nome utente",
    types: {
      0: "Super user",
      1: "Amministrazione aziendale",
      2: "Utenti generali dell'azienda",
      3: "Amministrazione QA",
      4: "Utenti generali QA",
      9: "Utenti generali ",
    },
    name: "Nome",
    phone: "Numero di telefono",
    company: "Nome Azienda",
    tenant: "Conduttore",
    lastLoginTime: "Ultimo accesso",
    resetPassword: "Reimpostare la password",
    reset: "Reset",
    is: "Di.",
    tip: {
      tips: "La password è a123456b",
      successful: "Reimpostare la password con successo",
      failed: "Impossibile reimpostare la password",
      mail: "Inserire l'indirizzo e-mail corretto",
      phone: "Inserire il numero di cellulare corretto",
      password: "Devono contenere sia lettere che numeri e avere una lunghezza compresa tra 8 e 30",
    },
    mail: "Email",
    password: "Password",
    account: {
      baseInfo: "Informazioni Base",
      oldPassword: "Vecchia password",
      newPassword: "Nuova password",
      confirm: "Conferma",
      tip: {
        confirm: "Incoerenza tra due password inserite",
      },
    },
    quality: "Ufficio Controllo Qualità",
    messageSendType: "Metodo di invio della notifica (CAPTCHA)",
    maintainer: "Addetto alla manutenzione",
    afterSale: "Post-vendite",
    clientUser: "Utilizzatore C-suite",
    cegion: "Regione",
    area: "Divisione geografica",
    branch: "Filiale",
    childCom: "Aziende della regione",
    serviceStation: "Stazione di servizio",
    station: "Stazioni sotto la filiale",
    messageControl: "SMS di controllo ",
    repair: "SMS per riparazione d'emergenza",
    maintenance: "SMS per manutenzione",
    alarm: "SMS di allarme",
    changePwdState: "Password modificabile",
    unselectedRole: "Ruolo non selezionato",
    selectedRole: "Ruolo selezionato",
    user: "Utente",
    userNameRule: "Il numero di conto è composto da 4~25 caratteri e può contenere solo lettere inglesi, numeri, @, punti, trattini bassi",
    passwordRule: "La password è composta da 8~30 caratteri, deve contenere lettere maiuscole, lettere minuscole e numeri",
    lockedAccount: "Conti bloccati",
    unlock: "Rilascio",
    unlockSuccess: "Sbloccato con successo",
    userInfo: "Informazioni sull'utente",
    isMaintWorker: "Se manutentore",
    roleIds: "Diritti dell'utente",
    nameRule: "Il nome non può essere vuoto",
    phoneRule: "Il numero di cellulare non può essere vuoto",
    validNumberNumber: "Inserire il numero di cellulare corretto",
    userTypeRule: "Il tipo di utente non può essere nullo",
    maintWorkerRule: "Se l'addetto alla manutenzione non può essere vuoto",
    correspondRoleRule: "Il ruolo corrispondente non può essere vuoto",
    assignAuthority: "Assegnazione delle autorità",
    correspondRole: "Ruolo corrispondente",
  },

  floorDisplay: {
    floorDisplay: "Confronto dei piani",
    floorDisplayNo: "Numero di casseforme per solai",
    no: "Numero",
    name: "Nome",
    setting: "Imposta",
  },

  // 电梯档案
  elevator: {
    eocdException: "EOCD Anormale",
    code: "Codifica",
    simExpireDate: "Data di scadenza della SIM card",
    dockingPlatform: "Piattaforma allegati",
    maintenanceMan: "Addetto alla manutenzione",
    report: "Statistici",
    batchCopy: "Copia in batch dell'ascensore corrente",
    sameMaintenanceWorker: "Non è possibile scegliere lo stesso addetto alla manutenzione!",
    maintenanceWorker: "Selezionare almeno un addetto alla manutenzione",
    selectMaintenance: "Selezionare la società di manutenzione",
    parameterEmpty: "I parametri non possono essere nulli",
    selectFloorDisplay: "Selezionare prima il piano da confrontare",
    belongCompany: "Società affiliata",
    salesman: "Venditore",
    yes: "Si",
    not: "No",
    confirmChoice: "Determinare la scelta",
    singleElevator: "Rapporto sulla scala singola",
    multiElevator: "Rapporto multi-scala",
    reportStateCycle: "Ciclo statistico di reporting",
    createReport: "Generazione di rapporti",
    checkMultiReport: "Visualizza rapporto multi-scala",
    no: "N° Ascensore",
    useUnit: "Identificazione edificio installazione",
    dtuCode: "Numero DTU",
    selectElevator: "Selezionare prima l'ascensore",
    selectReporting: "Selezionare il ciclo statistico di rendicontazione",
    selectOne: "Selezionare almeno uno",
    exFactoryNo: "Numero di fabbrica",
    person: "Contatti",
    manufactured: "Azienda produttrice",
    address: "Dettagli dell'indirizzo",
    esdt: "ESDT Gateway",
    esdtFault: "Record di guasto ESDT",
    emtFault:"Record di guasto EMT",
    nvrFault: "Record di guasto NVR",
    emt: "EMT Gateway ",
    otherRecord: "Altro registro",
    selfProtectionRecord: "Registro di auto-manutenzione",
    elevatorDetails: "Dettagli dell'ascensore",
    baseInfo: "Informazioni Base",
    iot: "IoT",
    elevatorParts: {
      elevatorParts: "Parti dell'ascensore",
      parts: "Accessorio",
      add: "Aggiungi",
      category: "Tipo",
      number: "Numero",
      name: "Nome",
      serialNumber: "Numero di serie",
      floor: "Piano",
      status: "Stato dell'autenticazione",
      activated: "Certificato",
      notActivated: "Non certificato",
      remove: "Rimosso/Svincolato ",
      activateTime: "Tempo di attivazione",
      activator: "Operatore",
      createTime: "Tempo di creazione",
      replace: "Sostituzione",
      replacementRecord: "Registro di sostituzione",
      oldParts: "Parti vecchie",
      newParts: "Parti nuove",
      replacementTime: "Tempo di sostituzione",
    },
    longAntLat: "Apparecchi Coordinate",
    unknown: "Sconosciuto",
    record: "Registrazione",
    locationInfo: "Informazioni sulla posizione",
    unselectedElevator: "Ascensore non selezionato",
    selectedElevator: "Ascensori selezionati",
    floorQr: "Scarica il pacchetto di codici QR per pavimenti",
    ownerPhoneNo: "Numero di cellulare del proprietario",
    isHomeUse: "Nazionale o meno",
    monitorTime: "Monitoraggio continuo Tempo/Minuto",
    autoGenerate: "Generazione automatica di rapporti giornalieri, settimanali e mensili",
    follow: "Segui",
    batchModification: "Modifica tutti",
    faultTemplate: "Template guasti",
    salesmanTel: "Telefono cellulare del venditore",
    alarmDelayTime: "Tempo di attesa dell'allarme",
    floorComparisonTable: "Confronto dei piani",
    callElevator: "Installazione delle scale",
    ota: "OTA",
    projectName: "Nome Progetto",
    faultCode: "Codice del guasto",
    elevator: "Ascensore",
    addElevator: "Aggiunta di un ascensore",
    elevatorType: "Tipi di ascensori",
    registerCode: "Codice dispositivo ",
    name: "Nome ascensore",
    brand: "Marca",
    model: "Caratteristiche",
    maintWorker: "Addetto alla manutenzione",
    maintType: "Tipo di manutenzione",
    safetyOfficer: "Responsabile della sicurezza",
    safetyDirector: "Direttore della sicurezza",
    safetyMainer: "Responsabile generale",
    buildingNo: "Numero impianto",
    elevatorNo: "Numero ascensore",
    nodeCode: "Nodo",
    monitorType: "Tipo di monitoraggio",
    monitorType1: "Protocolli",
    monitorType2: "Sensori periferici",
    gateWayType: "Gateway",
    gateWayType1: "ESDT",
    gateWayType2: "TPL-NVR",
    gateWayType3: "EMT",
    gateWayType4: "NVR",
    dtuModel: "Modello DTU",
    simCardNo: "Numero carta SIM",
    iccid: "ICCID",
    controlCabinetLeaveNo: "Numero identificativo del quadro elettrico",
    exFactoryNoWx: "Numero di fabbrica di Wuxi",
    outerDeviceWx: "Numero periferica Wuxi",
    statusWx: "Accessibilità",
    maintPeriod: "Ciclo di manutenzione (giorni)",
    whereUsed: "Luogo di utilizzo",
    videoEquip: "Apparecchi video",
    deviceGateway: "Dispositivi di gateway",
    notInstalled: "Disinstalla",
    installed: "Installato",
    installed_plug: "Installato(Modulo plug-in)",
    installed_link: "Installato(Modulo di collegamento)",
    videoChannelId: "ID telecamera auto",
    videoUrl: "Link al video del carrello",
    motorRoomVideoUrl: "Carrozza Video FLV",
    video: "Video",
    joinStatus: "Stato di accesso alla rete",
    isParallel: "Controllo parallelo/di gruppo",
    openStatus: "Se divulgare o meno",
    open: "Stato di apertura",
    notOpen: "non pubblico",
    halfOpen: "più o meno aperto",
    onlineStatus: "stato online",
    activatedPartsStatus: "Stato di autenticazione",
    accessStatus: "stato di accesso",
    accessPlatform: "Piattaforma allegati",
    activated: "Certificato",
    notActivated: "Non certificato ",
    all: "Tutto",
    accessed: "Collegato",
    notAccessed: "Non collegato",
    useStatus: "Stato di utilizzo",
    property: "Azienda proprietaria",
    maintenanced: "Azienda di manutenzione",
    propertyCom: "Azienda proprietaria",
    qualityOrgan: "Organismo di controllo qualità",
    installationEnterprise: "Azienda di installazione",
    reconstructed: "Azienda di modernizzazione",
    locCode: "Codice di localizzazione",
    exFactoryDate: "Data di fabbricazione",
    installationDate: "Data di installazione",
    joinDate: "Data di acquisizione",
    serviceDate: "Dati di utilizzo",
    test: "Debugging",
    check: "Rilevamento",
    floors: "Numero di livelli",
    ratedSpeedOfEscalator: "Velocità nominale della scala mobile (unità: m/s)",
    riseOfEscalator: "Altezza di sollevamento della scala mobile (unità: m)",
    angleOfEscalator: "Angolo di inclinazione della scala mobile (unità: gradi)",
    widthOfEscalator: "Larghezza nominale della scala mobile (unità: m)",
    ratedSpeedOfMovingWalk: "Velocità nominale della passerella mobile (unità: m/s)",
    lengthOfMovingWalk: "Lunghezza del tratto utilizzato dal viaggiatore (unità: m)",
    angleOfMovingWalk: "Angolo di inclinazione del carrello (unità: gradi)",
    widthOfMovingWalk: "Larghezza nominale del carrello (in m)",
    highestFloorOfElevator: "Numero di piani di ascensore (unità: piani)",
    ratedSpeedOfElevator: "Velocità nominale (m/s)",
    ratedSpeedOfElevatorRule: "Velocità nominale",
    ratedLoadOfElevator: "Capacità di carico nominale (kg)",
    floorDisplayTemplate: "Confronto tra i piani",
    actualFloor: "Piano attuale",
    displayedFloor: "Piano visualizzato ",
    stopStatus: "Stato di arresto del piano attuale",
    currentFloorCode: "Codice piano di stato (A0)",
    faultFloorCode: "Codice piano di malfunzionamento (MC)",
    faultFloor: "Codice piano",
    addFloorDisPlay: "Aggiungi display di piano",
    peopleStuckAlarmDelayTime: "Tempo di ritardo dell'allarme intrappolato (unità: sec)",
    notAvailableAlarmDelayTime: "Tempo di ritardo dell'allarme per mancato arresto dell'ascensore (unità: secondo)",
    finishDelayTime: "Tempo di ritardo del completamento (unità: secondo)",
    longitude: "Longitudini",
    latitude: "Latitudine",
    joined: "Abilitato a Internet",
    notJoined: "non ancora in rete",
    useStatus_1: "In Uso",
    useStatus_2: "Essere dichiarato senza valore",
    useStatus_3: "Interrompere l'uso",
    useStatus_4: "Disconnettersi",
    maintenanceBySelf: "Autoassicurazione",
    maintenanceByOthers: "Garantisce",
    production: "Dare alla luce un bambino",
    using: "Utilizzare",
    maintenance: "Manutenzione",
    system: "Sistema",
    fault: "Guasto",
    enterprise: "Informazioni Azienda",
    parameters: "Parametri dell'ascensore",
    extensionParameters: "Parametri estesi",
    modifyLocation: "Modifica posizione",
    generalParameters: "Parametro comune",
    floorDisplay: "Confronto tra i piani",
    alarmParameters: "Parametri di allarme",
    location: "Posizione dell'ascensore",
    emergencyRecord: "Registrazioni delle riparazioni d'emergenza",
    maintenanceRecord: "Registro della manutenzione",
    yearCheckRecord: "Registrazione delle ispezioni annuali",
    faultRecord: "Registrazione dei guasti",
    deviceEvent: "Record evento",
    potentialFault: "Rischi per la sicurezza",
    device: "Apparecchiature di rete",
    runTimes: "Corse",
    gradeAlarming: "Allarmi gerarchici",
    maintComSelectTip: "Selezionare prima la società di manutenzione",
    propertyComSelectTip: "Selezionare prima l'attività immobiliare",
    elevatorDebuggingPlatform: "Piattaforma di messa in servizio dell'ascensore",
    elevatorCheck: "Ispezione dell'ascensore",
    lock: "Serrattura",
    unlock: "Annullamento della scala di bloccaggio",
    Lock: "Bloccare ",
    Unlock: "Rilascio",
    closeAuth: "Disabilitare l'accesso",
    openAuth: "Accesso libero",
    activationBinding: "Attivare il vincolo",
    AllBoards: "Tutte le schede del sistema",
    elseBoards: "Altro (schede di espansione, ecc.)",
    simCardBinding: "Rilegatura della scheda Sim",
    groupControlBoard: "Scheda di controllo di gruppo (parallelo)",
    frequencyConverter: "Convertitore",
    carBoard: "Pannello auto",
    sedanRoof: "Tetto dell'auto",
    callBoard: "Scheda chiamante",
    changeProbationPeriod: "Modifica del periodo di prova",
    escalatorLock: "Scala di bloccaggio per scale mobili",
    escalatorUp: "Scala mobile in alto",
    escalatorDown: "Scala mobile abbassata",
    escalatorRun:"Funzionamento della scala mobile",
    escalatorStop:"Fermata della scala mobile",
    energryRun:"Funzionamento a risparmio energetico",
    fire:"Lotta antincendio",
    breakSystem:"Freno aggiuntivo",
    UnintendedReversal:"Inversione non manipolativa",
    missingStep:"Gradini o pedate mancanti",
    handSpeed:"Deviazione della velocità del corrimano",
    breakFail:"Guasto al freno di lavoro",
    otherFault:"Altri guasti che impediscono il riavvio delle scale mobili e dei marciapiedi mobili",
    datSta:"Statistiche",
    slow:"lento",
    fast:"presto",
    stop:"fermare",
    day: "Giorno",
    hour: "Ore",
    openDebugAuth: "Privilegi di debug aperti da remoto",
    grade: "Livello",
    time: "Tempo",
    stopOrStart: "Via di fuga (ad esempio di una nave)",
    lockOrUnlock: "Sblocco remoto dei numeri di contratto",
    contractNo: "Numero di contratto",
    refresh: "Aggiornamento",
    operationRecord: "Record di funzionamento",
    type: "Tipo di",
    order: "Comando",
    status: "Stato dell'autenticazione",
    operator: "Operatore",
    executing: "In corso",
    success: "Successo ",
    error: "Fallito ",
    overTime: "Retribuzione degli straordinari",
    copy: "Copia",
    batchAdding: "Lotto di nuovi ascensori",
    addNumber: "Numero di aggiunte",
    exportExcel: "Derivare",
    importSimInfo: "Importazione delle informazioni della scheda Sim",
    tip: {
      maintPeriod: "Il ciclo di manutenzione deve essere maggiore di 0",
      operateSuccess: "Successo dell'implementazione",
      operateError: "Fallimento dell'esecuzione",
      atLeastOne: "Selezionare almeno uno",
      daysAndHours: "Compilare correttamente i giorni e le ore",
      gradeAndTime: "Compilare correttamente il voto e l'ora",
      chooseFirst: "Selezionare prima",
      want: "Determinazione dell'implementazione",
      contractNo: "Il numero di contratto non può essere vuoto",
      batchAddition: "Aggiunta in batch senza codice dispositivo",
      needRegNO: "Non può essere vuoto, no Si prega di compilare il numero di fabbrica",
    },
  },

  msgPushRecord: {
    createTime: "Tempo di creazione",


  },

  gatewayVersion: {
    creator: "Compilatore",

  },

  // 智能摄像
  tpLink: {
    deviceStatus: "Stato del dispositivo",
    deviceName: "Nome del dispositivo di monitoraggio",
    boundElevator: "Ascensore associato",
    deviceType: "Tipo di apparecchiatura",
    deviceModel: "Modello di apparecchiatura",
    mac: "Indirizzo MAC",
    preview: "Anteprime",
    playBack: "Riproduzione",
    alarm: "Allarme segnalato",
    peoples: "Quorum",
    synchronizationSucceeded: "Sincronizzazione riuscita",
    synchronizationFailed: "Errore di sincronizzazione",
    alarmRecord: "Registri di allarme",
    videoType: "Tipo di video",
    synchronization: "Sincronizzato",
    alarmSetting: "Impostazioni inoltro allarme ",
    url: "Indirizzo di inoltro",
    shiLian: "SL-IPC-XSD001/E216",
    tpLink: "TPLink",
    cameraReplace: "La telecamera è legata a un altro ascensore. Dovremmo sostituirla?",
  },

  // 维保管理
  maintWorkOrder: {
    filter: "Ricerca parole chiave",
    clearSelection: "Selezione cancellata",
    branchAgency: "Agenzia di filiale",
    status: "Stato dell'autenticazione",
    maintType: "Tipo di manutenzione",
    maintItem: "Elementi di manutenzione",
    maintSetting: "Settaggio della manutenzione",
    safetySpotCheck: "Controllo di sicurezza casuale",
    productTypeName: "Tipo di ascensore",
    maintTypeName: "Nome del tipo di manutenzione",
    elevatorType1: "Ascensore per persone elettrico",
    elevatorType2: "Ascensore per cose elettrico",
    elevatorType3: "Ascensore montacarichi per cose elettrico",
    elevatorType4: "Ascensore idraulico per persone",
    elevatorType5: "Ascensore idraulico per merci",
    elevatorType6: "Scala mobile",
    elevatorType7: "Tappeto mobile",
    elevatorType8: "Ascensore antideflagrante",
    elevatorType9: "Ascensore antincendio",
    elevatorType10: "Montavivande",
    selectMaintItem: "Selezione del tipo di programma di manutenzione",
    maintenanceProjectName: "Nome del progetto di manutenzione",
    maintContent: "Contenuto della manutenzione",
    maintRequire: "Requisiti di manutenzione",
    maintArea: "Area di manutenzione",
    branchName: "Nome della filiale",
    salesOrderNo: "Numero d'ordine",
    phone: "Telefono",
    currentLocation: "Posizione attuale",
    contacts: "Contatti",
    featureDetails: "Dettagli caratteristici",
    featureNumber: "Numero di caratteristiche",
    featureName: "Nome delle caratteristiche",
    featureValue: "Valore caratteristico",
    completionStatus: "Stato di completamento",
    rectificationStatus: "Stato della rettifica",
    spotCheckResults: "Risultati di ispezione",
    spotCheckNumber: "Numero di controlli a campione",
    cegion: "Regione",
    branch: "Filiale",
    projectName: "Nome Progetto",
    maintenanceCompany: "Società di manutenzione",
    elevatorName: "Nome ascensore",
    deviceNumber: "Numero attrezzature",
    maintenanceBySelf: "Di auto-manutenzione",
    subcontract: "Subappalto ",
    spotCheckInspector: "Ispezione personale",
    maintenanceWorker: "Manutenzione personale",
    spotCheckDate: "Verificare la data",
    spotCheckInformation: "Verifica le informazioni",
    unrectified: "Non rettificato",
    rectified: "Rettificato",
    elevator: "Ascensore",
    specification: "Modello",
    totalScore: "Totali",
    maintenanceQuality: "Qualità della manutenzione",
    basicConditions: "Condizioni di base",
    checkContent: "Contenuto dell'ispezione",
    confirmProject: "Conferma dei progetti",
    safety: "Fideiussione",
    category: "Tipo",
    completionTime: "Tempo di completamento ",
    isQualified: "È qualificato?",
    signToConfirm: "Conferma della firma",
    spotCheckInspectorConfrim: "Confermati i controlli a campione",
    maintenanceWorkerConfirm: "Conferma del personale di manutenzione",
    type: {
      halfMonth: "Manutenzione bisettimanale",
      month: "Manutenzione trimestrale",
      halfYear: "Manutenzione semestrale",
      year: "Manutenzione annuale",
    },
    maintenanceArea: {
      machineRoom: "Locale macchine",
      bridge: "Cabina ascensore",
      layer: "Piano",
      wellRoadAndTheBottomPit: "Vano e Fossa",
      escalator: "Scala mobile",
      other: "Altri",
    },
    setting: {
      autoSendDay: "Orario di invio automatico degli ordini di lavoro per la manutenzione: in anticipo",
      maintDistance: "Distanza delle operazioni di manutenzione",
      meter: "Metri",
      isAutoGenerate: "Gli ordini di lavoro per la manutenzione sono generati automaticamente:",
      generateInitialTime: "Orario iniziale utilizzato per la generazione automatica degli ordini di lavoro:",
      completeDate: "Tempo di completamento",
      planDate: "Data pianificata",
      maintCycleDay: "Giorni del ciclo di manutenzione",
      day: "Giorni",
      signInMethod: "Metodo di accesso all'assegnazione",
      photo: "Scattare foto",
      isSignInApp: "Se il cliente è autorizzato a firmare dal lato dell'app del personale di manutenzione",
      isOperationOverdue: "Se è possibile continuare le operazioni dopo che l'ordine di lavoro per la manutenzione ha superato la data di scadenza ",
      isTwoMaintainerCheckIn: "Se è permesso ai due membri del personale di manutenzione di effettuare i check-in degli ordini in arrivo ",
      isPaperMaintPhotoRequired: "Se è necessario caricare foto degli ordini di lavoro cartacei",
      fieldWorkMethods: "Modalità di operazione in loco",
      default: "Predefinito: nessuna restrizione sul numero di operazioni che devono essere completate durante un'operazione, tranne per le operazioni di chek-in e fine",
      custom: "Personalizzazione",
      isPhotoCheckIn: "Deve scattare una foto per effettuare l'accesso ",
      isMaintainerMustSignInApp: "Il personale di manutenzione deve accedere all'app prima di completare l'ordine di lavoro ",
      isPhotoAbnormal: "Gli oggetti di manutenzione insoliti devono essere fotografati",
      isSoundRecordAbnormal: "Gli oggetti di manutenzione insoliti devono essere registrati ",
      isContextAbnormal: "Per gli oggetti di manutenzione insoliti, deve essere compilata una descrizione testuale",
      isPhotoNormal: "Per gli oggetti di manutenzione normali, devono essere scattate delle foto ",
      isSoundRecordNormal: "Per gli oggetti di manutenzione normali, devono essere effettuate delle registrazioni",
      isContextNormal: "Per gli oggetti di manutenzione normali, deve essere compilata una descrizione testuale ",
    },
    tip: {
      invalidSuccess: "Successo della nullificazione",
      invalidError: "mancata cancellazione",
      emptySingleRow: "Selezionare prima un record",
      getMaintTypeItemError: "Impossibile ottenere elementi di tipo manutenzione",
      maintTypeName: "Il nome del tipo di manutenzione non può essere vuoto",
      elevatorProductType: "Il tipo di scala non può essere vuoto",
      maintTypeClassify: "La classificazione del tipo di manutenzione non può essere vuota",
      getElevatorProductTypeError: "Impossibile ottenere il tipo di scala di sollevamento",
      itemName: "Il nome dell'elemento di manutenzione non può essere vuoto",
      maintContent: "Il contenuto della manutenzione non può essere vuoto",
      maintRequire: "I requisiti di manutenzione non possono essere vuoti",
      maintArea: "L'area di manutenzione non può essere vuota",
      sendTip: "Inserire un valore compreso tra 0 e 7. Un valore pari a 0 significa che non è previsto l'invio automatico",
      distanceTip: "Inserire un valore maggiore di 0",
      cycleTip: "Inserire un valore compreso tra 1 e 90.",
      score: "Valutare ogni articolo",
      elevator: "Selezionare un ascensore",
      date: "La data di manutenzione non può essere vuota",
      add: "Gli ascensori non possono essere aggiunti ripetutamente",
      confirm: "È un ordine di conferma?",
      emptyMultipleRow: "Scegli almeno un record.",
      confirmSend: "Confermare l'invio degli ordini di lavoro selezionati?",
      confirmInvalid: "Conferma della cancellazione del piano di manutenzione dell'ascensore selezionato, il piano di manutenzione cancellato non può essere ripristinato!",
      confirmDelete: "Confermare la cancellazione dei record selezionati? I record cancellati non sono recuperabili!",
      elevatorNo: "Il numero dell'ascensore non può essere vuoto",
      maintType: "Il tipo di manutenzione non può essere vuoto",
      emptyMaintType: "Selezionare il tipo di manutenzione",
      planStartDate: "La data di inizio del piano non può essere vuota",
      planEndDate: "La data di fine piano non può essere vuota",
      maintCycle: "Inserire un numero maggiore di 0",
      emptyPlan: "L'aggiunta di un nuovo piano di manutenzione non può essere vuota!",
      timeError: "L'ora di inizio deve essere maggiore dell'ora di fine.",
      timeError1: "Data attuale Intervallo di fuori servizio",
      invalidReason: "Il motivo della cancellazione non può essere vuoto",
    },
    condition: {
      notProcessed: "Non ancora elaborato",
      noSuchItem: "Nessuno",
      qualified: "Qualificato",
      qualifiedAfterAdjustment: "Qualificato dopo l'adeguamento",
      unqualified: "Non qualificato",
    },
    customerSignature: "Firmato dal cliente",
    dissatisfied: "Insoddisfatto",
    commonly: "Abituale",
    satisfied: "Soddisfatti",
    verySatisfied: "Molto soddisfatto",
    evaluation: "Valutazione della qualità del servizio di manutenzione:",
    sigAndReturnVisit: "Firma del cliente e visita di ritorno",
    uploadPicture: "Caricare le foto del sito",
    treatmentProcess: "Processo di trattamento",
    workCondition: "Situazione di lavoro",
    invalidReason: "Cause di abrogazione",
    generatePlan: "Generare un piano",
    checkMaintItem: "Guarda i progetti di manutenzione",
    selectElevator: "Scegli l'ascensore per entrare",
    plan: {
      planStartTime: "Data di inizio pianificata",
      planEndTime: "Data di completamento pianificata",
      no: "Nessuno",
      have: "Sì.",
      row: "Articolo:",
      selected: "Ascensori selezionati (è possibile aggiungere piani)",
      elevator: "Informazioni sull'ascensore",
      maintCycle: "Ciclo di manutenzione",
    },
    situationDescription: "Descrizione della situazione del sito",
    picture: "Fotografie della situazione del sito",
    soundRecord: "Registrazione audio della situazione sulla scena",
    processTime: "Tempo di elaborazione",
    maintBaseInfo: "Informazioni sull'ordine di lavoro",
    created: "Creato",
    haveMaintPlan: "Disponibilità dei programmi di manutenzione ",
    recentNotAccept: "Ultime 24 ore non accettate ",
    expired: "Scaduto senza manutenzione",
    maintWorkOrder: "Registro interventi di manutenzione",
    maintainerName1: "Addetto alla manutenzione1",
    maintainerName2: "Addetto alla manutenzione2",
    creator: "Creatore",
    invalid: "Non valido",
    normal: "Normale",
    advance: "Avanzamento",
    overdue: "Scaduto",
    notAccepted: "Non accettato",
    accepted: "Accettato",
    signedIn: "Firmato",
    completed: "Completato",
    notSignIn: "Non firmato",
    propertyCom: "Azienda di gestione immobiliare",
    maintDate: "Data della manutenzione",
    createTime: "Tempo di creazione",
    maintDuration: "Durata della manutenzione",
    all: "Tutto",
    send: "Inviato",
    completionSituation: "Situazione di completamento",
    maintPlan: "Piano di manutenzione",
    planDate: "Data della manutenzione programmata",
    orderNum: "Numero dell'ordine di lavoro",
    maintStatus: "Stato dell'autenticazione",
    maintDevice: "Raccomandazioni per la manutenzione",
    no: "Numero dell'ordine di lavoro",
    notSend: "Non inviato",
    company: "Assicurazione Società",
    date: "Data di manutenzione programmata",
    State: "Cambio di stato",
    ultraTime: "Scadenza",
    hasExtended: "In ritardo",
    inPlan: "In programma",
    execution: "In esecuzione",
    stocks: "Completato",
    confirmed: "confermato",
    affirm: "Confermato ",
    content: "Contenuto della manutenzione",
    userEvaluation: "Recensioni degli utenti",
    property: "Firma della proprietà",
    maintenance: "Firma dell'addetto alla manutenzione",
    satisfaction: "Soddisfazione sul lavoro",
    advice: "Commento",
    returnRecord: "Registri delle visite di ritorno",
    dateList: {
      plan: "Data del piano di manutenzione",
      start: "Data di inizio della manutenzione",
      completion: "Data di completamento della manutenzione",
      confirmation: "Data di conferma della manutenzione",
    },
    maintenanceStatus: {
      free: "Non è necessaria alcuna manutenzione",
      have: "Già mantenuto",
      need: "Devono essere sostituiti",
    },
  },

  maintenanceArea: {
    machineRoom: "Locale macchine",
    bridge: "Cabina ascensore",
    layer: "Piano",
    wellRoadAndTheBottomPit: "Vano e Fossa",
    escalator: "Scala mobile",
    other: "Altri",
  },

  // 智能屏幕
  elevatorLcd: {
    elevatorLcd: "Schermo Intelligente",
    programList: "Elenco dei programmi",
    videoUser: "Utente video",
    onLineStatus: "Stato online",
    elevatorNo: "N° Ascensore",
    elevatorName: "Nome ascensore",
    useUnitName: "Identificazione edificio installazione",
    terminalID: "Numero seriale del terminale",
    heartTime: "Tempo di segnale",
    online: "Online",
    offline: "Offline",
    currentProgram: "Elenco dei programmi attuali",
    previewProgram: "Anteprima dei programmi attuali",
    controlSystem: "Sistema di accesso",
    replace: "Questo ascensore è già legato ad altri terminali, viene sostituito?",
    elevatorDtuCode: "Numero DTU",
    tip: {
      lcdId: "Il Mac non può essere duplicato",
      mac: "Il MAC deve essere di 17 bit",
      password: "Lunghezza della password da 6 a 20 combinazioni di numeri e lettere",
      elevator: "L'ascensore non può essere vuoto.",
    },
    elevatorRegisterNo: "Codice dispositivo",
    lcdId: "mac",
    password: "Crittografico",
  },

  // 入网申请
  elevatorNetApply: {
    eleNotEmpyty:"La data di accesso alla rete dell'ascensore in rete non può essere vuota",
    dtuNotEmpyty:"Il numero DTU dell'ascensore connesso alla rete non può essere vuoto",
    search: "Cerca",
    cancel: "Cancella",
    elevatorName: "Nome ascensore",
    downloadTemplate: "Scarica modello di importazione",
    uploadType: "Caricare un file di tipo .xlsx",
    importSuccess: "Importato con successo",
    importFailure: "Errore di importazione",
    importOnly: "È possibile importare solo 1 file alla volta",
    operate: "Operazioni",
    edit: "Modifica",
    delete: "Cancella",
    save: "Salva",
    generateSuccess: "Generato con successo",
    importElevator: "Introduzione all'ascensore",
    createSource: "Crea una fonte",
    dtuCode: "Numero DTU",
    factoryNumber: "Numero di fabbrica",
    useUnit: "Identificazione edificio installazione ",
    manufacturerCompany: "Azienda produttrice",
    maintenanceCompany: "Azienda di manutenzione",
    webAdd: "Aggiunte alla pagina",
    webImport: "Importazione di pagine web",
    app: "App",
    apiImport: "API Importa",
    underReview: "In revisione",
    reviewed: "È stato verificato",
    useUnitDistrict: "Distretto gruppo utenti",
    companyInfo: "Informazioni Azienda",
    useUnitAddress: "Indirizzo gruppo utenti",
    manufactured: "Azienda produttrice",
    creditCode: "Codice creditizio sociale unificato",
    maintComName: "Azienda di manutenzione",
    maintEmpName: "Addetto alla manutenzione",
    maintEmpPhone: "Telefono manutentore",
    propertyCom: "Azienda proprietaria",
    elevatorInfo: "Informazioni Ascensore",
    sim: "Numero carta SIM",
    nodeCode: "Nodo",
    buildingNo: "Numero impianto",
    ladderNo: "Numero ascensore",
    elevatorType: "Tipo di ascensore",
    brand: "Marca",
    elevatorModel: "Caratteristiche",
    load: "Portata",
    speed: "Velocità del credito(m/s)",
    dtuModel: "Modello DTU",
    controlModel: "Metodo di controllo",
    regCode: "Codice dispositivo ",
    exFactoryDate: "Data di fabbricazione",
    installationDate: "Data di installazione",
    videoChannelId: "ID telecamera in cabina",
    addFloorMappings: "Aggiungere informazioni sul piano",
    displayCode: "Codice Display ",
    floorLocation: "Posizione dei piani",
    floorMappings: "Confronto dei piani",
    proName: "Nome Progetto",
    maintCreditCode: "Codice di credito azienda di manutenzione",
    createCreditCode: "Codice di credito azienda produttrice",
    installAddress: "Indirizzo di installazione",
  },

  // 合同管理
  contract: {
    check: "Controllare",
    contractInfo: "Informazioni relative al contratto",
    contractNo: "Numero del contratto",
    contractSource: "Fonte del contratto",
    contractStartDate: "Data di inizio del contratto",
    contractEndDate: "Data di fine del contratto",
    siteName: "Nome del sito",
    packagingForm: "Modello di imballaggio",
    inspectionUndertaker: "Responsabile dell'ispezione",
    speedLimiterUndertaker: "Responsabile del limitatore di velocità",
    brakeTestFeeUndertaker: "Responsabile delle spese per il test dei freni",
    freeAccessoriesQuota: "Quota  accessori gratuiti",
    contractPrice: "Prezzo del contratto (EURO)",
    paymentMethod: "Metodo di pagamento",
    monthlyUnitPrice: "Prezzo unitario mensile",
    agencyFees: "Commissioni d'agenzia",
    contactInformation: "Dettagli di contatto",
    isSubcontract: "Se previsto un subappalto",
    elevatorSelect: "Ascensori selezionati",
    tower: "Unità:",
    copy: "Copia",
    fileInfo: "Allegato informazioni",
    addFile: "Aggiungi allegato",
    fileName: "Nome dell'allegato",
    uploadTime: "Tempo di caricamento",
    uploader: "File caricato da",
    upload: "Caricare",
    download: "Scaricare",
    delete: "Cancella",
    uploadTypeLimit: "È possibile caricare solo file con suffisso jpg, png, bmp, jpeg, xlsx, xls, docx, doc",
    uploadSizeLimit: "La dimensione del file caricato non può superare i 10M",
    uploadSuccess: "Caricato con successo",
    uploadFailure: "Caricamento fallito",
    expiredStatus: "Stato scaduto",
    batchDelete: "Eliminazione di gruppo",
    appendix: "Allegato",
    importContract: "Importa contratto",
    remindExpired: "Promemoria di scadenza",
    beExpired: "Scaduto",
    expiresMonth: "Scadrà entro un mese",
    expiresThreeMonths: "Scadrà entro tre mesi",
    selectOneContract: "Selezionare almeno un contratto",
    voidedContract: "Conferma della cancellazione del contratto selezionato? Un contratto cancellato non può essere ripristinato!",
    firstSelectContract: "Selezionare prima un contratto",
    mostOneContract: "Selezionare fino a un contratto",
  },

  // 节目单
  programCard: {
    programCardName: "Nome del programma",
    notice: "Avviso",
    companyName: "Identificazione dell'azienda",
    notSupportVideo: "Il vostro browser non supporta la scheda Video",
    programCard: "Elenco dei programmi",
    edit: "Compilatore Elenco dei programmi",
    add: "Aggiuntivo Elenco dei programmi",
    prompt1: "* Supporta immagini (jpg/jpeg, png, gif) e video (mp4)。",
    prompt2: "* Tieni premuto il tasto Ctrl nella casella di selezione e clicca con il mouse per effettuare selezioni multiple",
    prompt3: "* la dimensione di un singolo video non deve superare i 300MB e la dimensione di una singola immagina non deve superare i 3MB",
    prompt4: "* Alcuni video mp4 potrebbero NON ESSERE VISUALIZZATI nel browser a causa di problemi di codifica, ma vengono riprodotti normalmente sullo schermo",
    upload: "Caricare",
    program: "Programmi",
    sorting: "Ordinamento",
    imageFiles: "I file di immagine non possono superare i 3MB!",
    videoFiles: "I file video non devono superare i 300MB!",
    fileType: "Caricare i tipi di file consentiti",
    uploadSuccessful: "Il caricamento è avvenuto con successo, fare clic su Salva per attivarlo",
  },

  company: {
    add: "+ Aggiuntivo",
    no: "Numero d'impresa",
    name: "Nome Azienda",
    abbreviation: "Abbreviazione dell'azienda",
    elevCount: "Numero ascensori",
    userCount: "Numero di utenti",
    type: "Tipo di azienda",
    phone: "Telefono",
    addUser: "Nuovi iscritti",
    factoryNo1: "Numero del produttore",
    brand: "Marca",
    person: "Contatti",
    unifiedSocialCreditCode: "Codice creditizio sociale unificato",
    externalNo: "Numero esterno",
    parentId: "Sede principale",
    dedicatedCode: "Codice specifico dell'azienda",
    company: "Aziende",
    tip: {
      no: "Il numero dell'organizzazione non può essere vuoto",
      name: "Il nome non può essere vuoto",
    },
    manufacturerCompany: "Azienda produttrice",
    maintenanceCompany: "Azienda di manutenzione",
    installationCompany: "Azienda di installazione",
    propertyCompany: "Azienda di gestione immobiliare",
    modificationCompany: "Azienda per interventi straordinari",
    useUnit: "Identificazione edificio installazione",
    tenant: "Conduttore",
    belongingCompany: "Affiliated enterprise",
    installPhone: "Contact telephone number of installation unit",
  },

  useUnit: {
    name: "Nome",
    buildingCount: "Numero di edifici",
    districtId: "Area",
    address: "Dettagli dell'indirizzo ",
    unitCode: "Codice di credito",
    coordinate: "Coordinate",
    lng: "Longitudine",
    lat: "Latitudine",
    person: "Contatti",
    contactTel: "Numero di telefono",
    propertyComId: "Azienda proprietaria",
    tip: {
      useUnitName: "Inserire il nome dell'unità utente",
      name: "Il nome non può essere vuoto",
      propertyComId: "La proprietà non può essere vuota",
      districtId: "L'area non può essere vuota",
      lng: "La longitudine non può essere vuota.",
      lat: "La latitudine non può essere vuota",
    },
    no: "Numero",
    type: "Tipo di",
    note: "Note",
  },

  route: {
    maintPlan:"Piano di manutenzione",
    bigDataManager: "Statistiche dei dati",
    aiManger: "Monitoraggio IA",
    createCompany: "Azienda produttrice",
    maintCompany: "Azienda di manutenzione",
    propertyCompany: "Azienda di gestione immobiliare",
    installCompany: "Azienda di installazione",
    changeCompany: "Azienda per interventi straordinari",
    useUnitCom: "Identificazione edificio installazione ",
    camera: "Telecamera intelligente connessa",
    monitorDevice: "Gestione telecamere",
    home: "Home page",
    account: "Gestione account",
    notice: "Informazioni sull'annuncio",
    uiSetting: "Impostazioni dell'interfaccia",
    noticeDetail: "Dettagli dell'annuncio",
    elevator: "Menù elevatori",
    peopleCounting: "Statitiche del personale",
    elevatorInfo: "Informazioni Ascensore",
    device: "Gestione Dispositivi",
    danger: "Indice di salute",
    elevatorUser: "Utenti ascensore",
    monitor: "Monitoraggio dell'ascensore",
    useUnitMonitor: "Monitoraggio di gruppo",
    map: "Mappa GIS",
    singleMonitor: "Monitoraggio Singolo Ascensore",
    videoMonitor: "Monitoraggio video",
    videoCall: "Videochiamata",
    monitorCenter: "Centro di monitoraggio ",
    emergencyRepair: "Riparazione d'emergenza dell'ascensore",
    repairJob: "Registro interventi di riparazione ",
    annualInspection: "Ispezione annuale dell'ascensore",
    annualInspectionRecord: "Registrazione delle ispezioni annuali",
    userRole: "Ruolo dell'utente",
    user: "Gestione degli utenti",
    role: "Gestione dei ruoli",
    baseInfo: "Informazioni Base",
    useUnit: "Identificazione edificio installazione ",
    gatewayVersion: "Versione del programma Gateway",
    thirdPartyPlatform: "Piattaforma di parti terze",
    messagePush: "Invio messaggio",
    parts: "Gestione dei componenti",
    partsType: "Tipo di accessorio",
    company: "Gestione aziendale",
    qualitySupervision: "Organismo di controllo qualità",
    videoTerminal: "Schermo Intelligente",
    videoUser: "Utente video",
    dtuTest: "Test Gateway",
    floorDisplay: "Confronto dei piani",
    faultTemplate: "Template guasti",
    developerPermission: "Sviluppatore",
    userDebugInfo: "Personale di debugging",
    potentialFaultsetting: "Impostazioni per i rischi di sicurezza",
    deviceChangeRecord: "Registrazione degli eventi",
    mb: "Registrazione degli eventi (obsoleto)",
    mbPowerLoss: "Sistema di alimentazione anomalo(blackout)",
    faultRecords: "Registrazione dei guasti",
    integration: "Configurazione di docking",
    alarmRecords: "Campanello d'allarme",
    elevatorMaintenance: "Manutenzione dell'ascensore",
    maintenanceWorkOrder: "Registro interventi di manutenzione",
    safeCheckOrder: "Ordini di lavoro di controllo a campione",
    maintenanceSetting: "Impostazioni di manutenzione",
    modSalesOrder: "Ordine di modifica",
    upkeep: "Gestione della manutenzione",
    accessPlatform: "Accesso alla piattaforma",
    oldMaintenanceWorkOrder: "Vecchio Registro interventi di manutenzione",
    msgSend: "Registri di invio SMS",
    userTakeRecord: "Registri delle chiamate dell'ascensore",
    msgTemplate: "Configurazione SMS",
    sysItem: "Parametri di sistema",
    system: "Gestione del sistema",
    event: "Gestione degli eventi",
    document: "Gestione dei dati",
    video: "Informazioni video ",
    banner: "Contenuti in slideshow",
    product: "Informazioni sul prodotto",
    fileManage: "Gestione dei file tramite scansione del codice",
    ztVersion: "Piattaforma privata",
    equip: "Dispositivi di gateway",
    shanghai: "Attracco a Shanghai",
    wuxi: "Attracco a Wuxi",
    chongqing: "Attracco a Chongqing",
    volks: "Attracco Vaux",
    shenyang: "Attracco a Shenyang",
    jiaxing: "Attracco di Jiaxing",
    guangzhou: "Attracco a Guangzhou",
    quzhou: "Attracco a Quzhou",
    guangxi: "Attracco a Guangxi",
    zhejiang: "Attracco a Zhejiang",
    fujian: "Attracco a Fujian",
    nanchang: "Attracco a Nanchang",
    shaoxing: "Attracco di Shaoxing",
    hangzhou: "Attracco a Hangzhou ",
    ningbo: "Attracco a Ningbo",
    liaoning: "Attracco Liaoning",
    huzhou: "Attracco a Huzhou",
    elevatorNetApply: "Configurazione accesso alla rete",
    homeUser: "Utente piattaforma",
    newMsgSend: "Registrazione dei messaggi",
    deviceGateway: "Dispositivi di gateway",
    tenant: "Gestione manutentore(non utilizzare)",
    Operations: "Gestione operazioni",
    kanban: "Visualizzazione della dashboard",
    userLoginLog: "Log di accesso al portale",
    userOperateLog: "Log  operazioni",
    elevatorSetting: "Impostazioni  file",
    potentialFaultSetting: "Impostazione dei rischi di sicurezza",
    systemSetting: "Impostazoni di sistema",
    contract: "Gestione dei contratti",
    programCard: "Elenco dei programmi ",
    smartScreen: "Schermo Intelligente",
    tpLinkVideo: "Webcam Tplink",
    vmVideo: "Telecamera intelligente",
    eocdPotential: "Rischi per la sicurezza",
    dayWeekMonthManger: "Supervisione giornaliera, settimanale e mensile",
    dayControl: "Ispezione settimanale",
    weekCheck: "Sondaggio settimanale",
    vxeTableTest: "Test di VxeTable",
    monthDispatch: "Pianificazione mensile",
    checkTemplates: "Modello risoluzione problemi",
    brand: "Marca Ascensore",
  },

  tenant: {
    dataBelong: "Affiliazione dati ",
    tenantManage: "Gestione manutentore",
    unitInformation: "Info unità",
    user: "Utente",
    tplinkCamera: "Webcam Tplink",
    smartScreen: "Schermo Intelligente",
    belongUnit: "Affiliazioni",
    belongTenant: "Conduttore subordinato",
    elevatorId: "ID ascensore",
    tenant: "Conduttore",
    loginFailed: "Accesso fallito",
    settingsSelected: "Nessun elemento in impostazione selezionato",
    tenantNotNull: "Il campo relativo all'utente non può essere lasciato vuoto",
    tenantStatus:"Stato dell'inquilino",
    tenantName:"Nome dell'inquilino",
    company:"Azienda iniziale",
    freeze:"congelare",
    createTime:"Ora di creazione",
    updateTime:"Ora di aggiornamento",

  },

  // 急修工单
  workOrder: {
    elevatorInfo: "Informazioni Ascensore",
    choiceDate: "La data di scadenza del contratto deve essere superiore alla data di inizio",
    onLine: "Online",
    offLine: "Offline",
    createTime: "Tempo",
    workOrderType: "Metodo di gestione",
    alarmType: "Tipo di allarme",
    workOrderStatus: "Stato del lavoro di riparazione",
    alarmTime: "Orario dell'allarme",
    workOrderNo: "Numero dell'ordine di lavoro",
    orderCancel: "Cancellazione dell'ordine di lavoro",
    handleMethod: "Metodo di gestione",
    faultReason: "Motivo del guasto",
    orderConfirm: "Conferma dell'ordine di lavoro",
    elevator: "Ascensore",
    faultDesc: "Descrivere l'errore nella chiamata",
    callRecord: "Registrazione della chiamata",
    alarmPeopleTel: "Telefono della persona che ha inviato l'allarme",
    callTime: "Tempo di chiamata",
    manualAlarm: "Allarme manuale",
    automaticAlarm: "Allarme automatico ",
    deviceReasonTrapped: "Manuale-A1（Intrappolato a causa del dispositivo）",
    deviceReasonSafe: "Manuale-A2（Problema di sicurezza a causa del dispositivo）",
    elevatorDamageOrFault: "Manuale-B（Danni all'ascensore o rischio di guasto）",
    minorIssues: "Manuale-C（Problema minore）",
    userReasonNoTrapped: "Manuale-D1（Non Intrappolato a causa Utente）",
    userReasonTrapped: "Manuale-D2（Intrappolato a causa Utente）",
    workOrder: "Ordine di lavoro",
    information: "Informazioni sull'ordine di lavoro",
    alarmType_0: "Non disponibile",
    alarmType_1: "Bloccato",
    alarmType_2: "Campanello d'allarme",
    alarmType_3: "Guasto grave",
    deviceCode: "Numero di apparecchiatura",
    acceptor: "Persona che ha preso in carico l'allarme",
    repairPeople: "Chiamante",
    repairMethod: "Metodo di riparazione",
    selfRepair: "Allarme automatico",
    phoneCall: "Telefono riparazione",
    inspection: "Ispezione annuale",
    contactPerson: "Contatti",
    contactTel: "Telefono",
    servicePersonnel: "Personale di servizio",
    hasPeople: "Occupato ",
    noPeople: "Nessuno",
    recordState_0: "Allarme segnalato",
    recordState_1: "Allarme accettato",
    recordState_2: "Arrivato",
    recordState_3: "Completato",
    recordState_4: "Ripristino",
    recordState_5: "Due allarmi completati",
    recordState_6: "Confermato",
    recordState_7: "Conferma automatica",
    recordState_8: "Revoca",
    received: "Allarme accettato",
    finished: "Completato",
    completed: "Completato",
    confirmed: "Confermato",
    canceled: "Revoca",
    receive: "Allarme accettato",
    finish: "Completato",
    complete: "Completato",
    confirm: "Confermato",
    cancel: "Revoca",
    alarmReported: "Allarmato",
    notReported: "Non ancora allertato",
    alarmed: "Ricevuto",
    unAlarmed: "Nessuna risposta",
    arrived: "Si è presentato",
    notArrived: "Non si è presentato",
    finished_: "Completato",
    recovery: "Ripristinato",
    notFinished: "Non completato",
    twoAlarmCompleted: "Due allarmi completati ",
    confirmed_: "Confermato",
    notConfirmed: "Conferma automatica",
    autoConfirmed: "Non confermato",
    cancelled: "Ritirato",
    baseInfo: "Informazioni Base",
    alarmReportedBy: "Persona che ha inviato l'allarme",
    emergencyRepairRecord: "Registrazioni delle riparazioni d'emergenza",
    system: "Sistema",
    faultRecords: "Registrazione dei guasti",
    faultNo: "Codice  guasto",
    faultType: "Tipo del guasto",
    faultTime: "Orario del guasto",
    mainBoardTime: "Tempo della scheda madre",
    faultStorey: "Piano guasto",
    faultFloorCode: "Codice piano guasto",
    faultFloor: "Codice piano",
    displayFaultFloor: "Piano visualizzato",
    unKnowFault: "Guasto sconosciuto",
    monitorElevator: "Monitoraggio degli ascensori",
    propertyInfo: "Informazioni sulla proprietà",
    revocationReason: "Motivi del ritiro",
    confirmPeople: "Persone confermate",
    propertyName: "Nome della proprietà",
    hex: "Esadecimale",
    workorderInfoTrack: "Tracciamento delle informazioni sugli ordini di lavoro",
    deviceInfo: "Registri delle apparecchiature",
    status: "Stato dell'autenticazione",
    launcher: "Meccanismo di lancio",
    rescueMap: "Mappa di salvataggio",
    currentLocation: "Posizione attuale > Riparazione d'emergenza dell'ascensore",
    tip: {
      stoped: "Questo processo di ordine di lavoro è stato sospeso e non può essere gestito",
      cantRepeat: "Non è possibile aggiungere ripetutamente personale di emergenza",
      want: "Sei sicuro",
      mark: "？",
    },
  },

  elevatorUser: {
    selectLift: "Seleziona ascensore",
    selectUser: "Selezionare l'utente",
    elevatorUser: "Manutenzione del rapporto con l'utente dell'ascensore",
    elevatorNotSelected: "Ascensore non selezionato",
    userNotSelected: "Utenti non selezionati",
  },

  faultTemplate: {
    SerialNo: "Numero di serie",
    faultCode: "Codice del guasto",
    faultType: "Tipo del guasto",
    no: "Numero",


  },

  ota: {
    applyDetails: "Dettagli sull'applicazione",
    operateTime: "Ora dell'operazione",
    balance: "Bilanci",
    apply: "Applicazione ",
    burn: "Masterizzare ",
    type: "Tipo di",
    operateRecord: "Record di funzionamento",
    burningTimes: "Numero di bruciature disponibili",
    name: "Nome",
    account: "Account",
    closure: "Coagulazione",
    pass: "Superato",
    reject: "Rifiuto",
    rejectPeople: "Rifiutatore",
    rejectReason: "Approvazioni",
    rejectTime: "Tempo di rifiuto",
    applyProgram: "Procedure di applicazione",
    applicant: "Richiedente",
    applicantAccount: "Numero di conto del richiedente",
    applicantName: "Nome del richiedente",
    applicationTime: "Tempo di applicazione",
    approver: "Approvatore",
    approverTime: "Tempo di approvazione",
    approverAccount: "Numero di conto dell'approvatore",
    approverName: "Nome del conto dell'approvatore",
    details: "Dettagli",
    programList: "Elenco dei programmi",
    applyList: "Elenco delle applicazioni",
    user: "Utente",
    isEffective: "Bloccato o meno",
    locking: "Bloccare ",
    effective: "Efficaci",
    program: "Nome del programma",
    count: "Numero di volte",
    updateTime: "Tempo di aggiornamento",
    applyNumber: "Numero di domande",
    remainingNumber: "Numero di volte rimanenti",
    note: "Osservazioni sulla domanda",
    numberNotNull: "Il numero di operazioni non può essere nullo",
    applyNumberMore: "Il numero di domande non può essere superiore al numero di domande rimanenti",
    approvalStatus: "Stato di approvazione",
    applying: "Applicazione in corso",
    approved: "Concordato",
    rejected: "Rifiutato",
    endTime: "Ora di fine",
    currentNumber: "Attualmente bruciabile",
    ownedCompany: "Aziende affiliate",
  },

  // 日周月管理
  dayWeekMonthManger: {
    elevatorName: "Nome ascensore",
    operate: "Operazione",
    filter: "Ricerca per parola chiave",
    status: "Stato dell'autenticazione",
    isAbnormal: "È anormale",
    abnormal: "Eccezioni",
    notAbnormal: "Niente di eccezionale",
    number: "Numero di serie",
    elevatorNo: "Numero dell'ascensore",
    useUnit: "Uso delle unità",
    controlResult: "Controllo dei risultati",
    controlDate: "Data di controllo",
    checkResult: "Esaminare i risultati",
    dispatchResult: "Trasferimento dei risultati",
    checkCycle: "Ciclo di drenaggio",
    to: " Da ",
    createTime: "Tempo di creazione",
    creator: "Compilatore",
    edit: "Modifica",
    delete: "Cancella",
    view: "Visualizza",
    anomalies: "Articolo insolito",
    ok: "Ok",
    cancel: "Cancella",
    index: "Numero",
    type: "Tipo",
    controlItems: "Progetto Day Control",
    checkItems: "Progetto di programmazione settimanale",
    dispatchItems: "Movimento mensile dei progetti",
    handleResult: "Risultato",
    count: "Numero di stazioni aperte",
    dispatchCycle: "Ciclo di programmazione",
    templateNo: "Numero del modello",
    templateName: "Nome del modello",
    enterprise: "Aziende affiliate",
    isDefault: "Predefinito o meno ",
    showAll: "Mostra tutti",
    hideItems: "Oggetti nascosti",
    show: "Dimostrare",
    projectType: "Tipo di progetto",
    projectName: "Nome Progetto",
    precaution: "Misure di protezione adottate",
    safetyOfficerOpinion: "Commenti del responsabile della sicurezza",
    safetyDirectorOpinion: "Parere del Direttore della sicurezza",
    risk: "Verifica della correzione dei rischi e dei pericoli per la sicurezza nell'ultima settimana",
    rectification: "Principali rischi e pericoli per la sicurezza e correzione durante la settimana",
    safetyManagementEvaluation: "Valutazione della gestione della sicurezza durante la settimana",
    workFocus: "Punti salienti del lavoro della prossima settimana",
    mainProblem: "I temi principali dell'ispezione di questo mese",
    rectification1: "Attuazione del programma di rettifica del mese",
    unresolvedProblem: "Problemi irrisolti del mese",
    scheduleContent: "Contenuti relativi alla pianificazione mensile (come la modifica del sistema i cambiamenti di responsabilità del personale ecc)",
    safetyContent: "Altre questioni di sicurezza",
    precaution1: "Misure esaminate dalla Conferenza",
    tip: {
      confirmDelete: "Confermare la cancellazione?",
      deleteSuccess: "Eliminato con successo",
      deleteError: "Cancellazione non riuscita",
      handleResult: "Inserire il risultato dell'elaborazione",
    },
  },

  developerDataAuthority: {
    developerDataAuthority: "Privilegio dei dati",
    useUnitNo: "Unità d'uso n.",
    useUnitName: "Nome",
  },

  developer: {
    developer: "Sviluppatori",
    no: "Codice",
    name: "Nome",
    appKey: "AppKey",
    appSecret: "AppSecret",
    contact: "Contatti",
    mobile: "Telefono",
    address: "Indirizzo",
    status: "Stato dell'autenticazione",
    auth: "Competenza",
    activate: "Attivazione",
    close: "Chiusura",
    baseUrl: "Ubicazione base di interfaccia",
    type: "Tipo",
    baseAlarmUrl: "Interfaccia allarme push Indirizzo di base",
    baseAlarmStatusUrl: "Stato di allarme Interfaccia push Indirizzo di base",
    baseFaultUrl: "Interfaccia Fault Push Indirizzo base",
    baseEventUrl: "Indirizzo base dell'interfaccia Event Push",
    baseTerminalUrl: "Stato del dispositivo IoT Indirizzo base push",
    information: "Informazioni di base",
    Subscription: "Informazioni sull'abbonamento",
    subscriptionServiceList: {
      alarm: "Allarme segnalato",
      remove: "Eliminare gli allarmi",
      repeal: "Annullamento dell'allarme",
      affair: "Evento",
      fault: "Fallimento",
    },
    tip: {
      no: "Il codice non può essere vuoto",
      name: "Il nome non può essere vuoto",
      mobile: "6~Inserire il numero di cellulare corretto",
      appSecret: "La lunghezza dell'AppSecret è una combinazione di 8 numeri e lettere",
      activateSucceed: "Successo dell'attivazione",
      closeSucceed: "Chiuso con successo",
    },
  },

  deviceGateway: {
    MakeSureProtocols: "Determinare l'impostazione per supportare il protocollo?",
    dtuNotNull: "La DTU non può essere vuota",
    lastTime1: "Tempo finale",
    pushCount: "Numero di spinte",
    dtuNode: "Numero DTU:Nodo",
    processing: "Il numero di push è eccessivo, il programma del gateway può essere anomalo, si prega di contattare il personale competente per il trattamento relativo",
    GatewayPushStatistics: "Statistiche push del gateway",
    refresh: "Aggiorna",
    sendA0: "Inviare A0",
    type: "Tipo di",
    packet: "Datagramma",
    receivingTime: "Tempo di ricezione",
    repair: "Riparazione dei dati dello stato di Shenyang-A0",
    runNumber: "Numero di corse",
    runTime: "Tempo di esecuzione",
    setting: "Imposta",
    read: "Recuperare",
    clear: "Chiaro",
    lastTime: "A0 Dimensione del conteggio della corsa corrente rispetto all'ultima",
    dockingOffline: "L'attracco di Shenyang è offline per un'interruzione di corrente di 60 secondi",
    OfflinePower: "Offline -> Interruzione di corrente",
    A0Running: "A0 Tempo di avvio/arresto del funzionamento",
    initialRunNumber: "Numero di corse iniziali",
    initialRunTime: "Tempo di esecuzione iniziale",
    currentRunNumber: "Numero attuale di corse",
    currentRunningTime: "Tempo di esecuzione corrente",
    polarP: "Il logaritmo polare p",
    motorType: "Tipo di motore",
    synchronous: "Sincronizzazione",
    asynchronous: "Asincrono",
    MkEvent: "Simulazione di un incidente in ascensore (nuovo incidente MK)",
    automatic: "Automazione",
    overhaul: "Riparazione",
    normal: "Normalità",
    AlarmBell: "Campanello d'allarme",
    PowerGateway: "Accensione del gateway",
    GatewayPowered: "Spegnimento della rete",
    orderMkEvent: "Simulazione di ordini di riparazione di emergenza (nuovo incidente MK)",
    NotTrappingPeople: "Nessuno stanco",
    TrappingPeople: "Persone intrappolate",
    faultCode: "Codice di guasto",
    sendOrder: "Inviare l'ordine di lavoro",
    confirmSettings: "Determinare le impostazioni?",
    clearSuccess: "Lo svuotamento è riuscito!",
    eventSendSuccess: "L'evento è stato inviato con successo!",
    orderSendSuccess: "Ordine di lavoro inviato con successo！",
    confirmClear: "Sicuro che sia vuoto?",
    elevatorBind: "Rilegatura a sollevamento",
    groupControl: "È controllato in parallelo",
    normallyOpen: "Spesso aperto",
    normallyClose: "Spesso chiuso",
    updateOnlineStatus: "Aggiornamento dello stato online",
    updateBindingStatus: "Aggiorna stato di associazione",
    gatewayRouteSet: "Set Gateway Route",
    pushStatistics: "Statistiche di invio",
    monitor: "Monitoraggio",
    video: "Video",
    selfStudy: "Autoapprendimento ",
    close: "normal close",
    open: "normal open",
    singleDoor: "Singola porta",
    doubleDoor: "Doppia porta",
    smoke: "Singola porta + fumo",
    openDoorInPlace: "Porta singola + porta aperta in posizione",
    other: "Altri",
    uModel: "Tipo U",
    wired: "Cavo",
    networkFormat: "Formato  rete",
    sensor: "Sensore",
    infraredType: "Tipo di infrarossi",
    elevatorSpeed: "Velocità dell'ascensore",
    carDoorType: "Tipo di porte di cabina",
    judgmentsNumber: "Conteggio anomalo",
    ESDT: "ESDT Gateway",
    EMT: "EMT Gateway ",
    NVR: "NVR Gateway",
    openDoorTime: "Tempo di apertura",
    horizontalSpacing: "Distanza tra i piani",
    editBlack: "Modifica della lista nera",
    editRoute: "修改网关路由",
    downloadingTemplate: "Scarica modello di importazione",
    blacklist: "Liste nere",
    supportProtocol: "Protocolli supportati ",
    bound: "Non vincolato",
    unbound: "Legato",
    selectBlacklist: "Selezionare prima il dispositivo gateway di cui si desidera impostare la blacklist nell'elenco",
    selectSupportProtocol: "Selezionare prima il dispositivo gateway che deve impostare i protocolli supportati nell'elenco",
    editDevice: "Modifica del dispositivo gateway",
    import: "Importa",
    dtuCode: "Numero seriale del terminale ",
    ip: "ip",
    deviceModel: "Modello di apparecchiatura",
    signalType: "Tipo di segnale",
    simCode: "Numero carta SIM",
    simICCID: "ICCID",
    signalStrength: "Intensità del segnale",
    softVer: "Versione del software",
    hardVer: "Versione hardware",
    bindState: "Stato di associazione",
    temperature: "Temperatura d'esercizio",
    devicePower: "Potenza",
    linkStatus: "Stato di collegamento della scheda madre",
    blackStatus: "Liste nere",
    blackYes: "Sì.",
    blackNo:"NO",
    dtuStatus: "Stato online",
    expirationTime: "Data di scadenza della SIM card",
    updateTime: "Ultimo aggiornamento ",
    liveSubscription: "Sottoscrizione in tempo reale ",
    liveStop: "Arresto in tempo reale",
    onlineElevator: "电梯在线同步",
    state: "Stato",
    audio: "Audio",
    inNetState: "Stato di accesso alla rete",
    communicationFailed: "Disconnessione",
    communicationNormal: "Normalità",
    dtuOnline: "On-line",
    call: "Durante una chiamata",
    dtuOffline: "Offline",
    onlineState: "Stato online",
    onlineUpdateTime: "Ultima ora online",
    invalid: "Non valido",
    accessTime: "Tempo di accesso",
    deviceCard: "È possibile selezionare un solo dispositivo gateway per visualizzare le schede di traffico.",
    trafficCard: "Selezionare prima il dispositivo gateway di cui si desidera visualizzare la scheda traffico nell'elenco",
    selectOne: "È possibile selezionare un solo dispositivo gateway",
    deviceStatus: "Per la visualizzazione dello stato è possibile selezionare un solo dispositivo gateway.",
    checkDevice: "Selezionare dall'elenco il dispositivo gateway di cui si desidera verificare lo stato",
    no: "No",
    yes: "Sì.",
  },

  device: {
    second: "secondo",
    min: "Minuti",
    record: "Registrazione online.",
    code: "Numero",
    dtuNum: "Numero di DTU",
    nodeNum: "Numero di nodi",
    onlineNode: "Nodo online",
    status: "Stato dell'autenticazione",
    online: "Online",
    offline: "Offline",
    port: "Porta",
    video: "Video",
    softwareVersion: "Versione del software",
    hardwareVersion: "Versione hardware",
    signalIntensity: "Intensità del segnale",
    temperature: "Temperatura",
    electricQuantity: "Potenza",
    setUp: "Imposta",
    read: "Leggi",
    APNName: "Nome APN",
    userName: "Nome utente",
    password: "Codice",
    all: "Tutto",
    refresh: "Aggiornamento",
    simCard: "Carte di flusso",
    upgrade: "Upgrade",
    monitor: "Monitoraggio",
    expandAll: "Espandi tutto ",
    collapseAll: "Comprimi tutto ",
    upgradeConfirm: "Confermare di eseguire l'aggiornamento?",
    iccidIsEmpty: "Iccid è vuoto",
    offlineDuration: "Periodo offline",
    within2Hours: "Entro 2 ore",
    more2hoursLess1Day: "Più di 2 ore e non più di 1 giorno",
    moreThan1Days: "Più di 1 giorno",
    custom: "Personalizzazione",
    minute: "Minuti",
    hour: "Ore",
    day: "Dio.",
    illegalType: "Importazione illegale.",
    cannotBeGreater: "L'ora di inizio non può essere maggiore o uguale all'ora di fine",
    pleaseEnter: "Si prega di inserire l'ora di ricerca",
    intercom: "Parlare installazione",
    serverAddress: "Indirizzo del server",
    callNos: "Numero di chiamata",
    dutyRoom: "Sala di servizio.",
    volume: "Volume",
    inFilter: "Parliamo di filtraggio",
    inFilterOpen: "Aperto",
    inFilterClose: "Chiudi",
    account: "Il conto SIP",
    readLoadingTxt: "Parametri in lettura, per favore pazienza",
    setLoadingTxt: "Parametri in corso di scrittura, si prega di avere pazienza",
    readSuccess: "Parametri di lettura riusciti",
    readFail: "Parametri di lettura non riusciti",
    writeSuccess: "Parametri impostati con successo",
    writeFail: "Impostazione dei parametri non riuscita",
    onlineRate: "Tasso di ascensore in linea",
  },

  // 事件管理
  event: {
    reset: "Ripristinare",
    export: "Esportazione dati",
    directElevator: "Ascensore",
    escalator: "Scala mobile",
    newEvent: "Nuovo evento",
    elevatorDPC: "Ingresso veicolo elettrico",
    dtuNumber: "Numero DTU",
    faultRecoverTime: "Tempo di recupero del guasto",
    faultSn: "Numero della registrazione del guasto",
    warning: "Suggerimento: Inserire il numero di terminale, altrimenti il risultato della query sarà vuoto.",
    filter: "Ricerca parole chiave",
    occurrenceTime: "Tempo di accadimento",
    elevatorNo: "N° Ascensore",
    elevatorName: "Nome ascensore",
    node: "Nodo",
    useUnit: "Identificazione edificio installazione",
    gatewayPowerDown: "Spegnimento del gateway",
    nonGateAreaStop: "Arresto fuori dalla zona di sblocco delle porte",
    alarmBell: "Campanello d'allarme",
    enterStopService: "Inserisci servizio di arresto",
    gatewayPowerFailure: "Spegnimento del gateway",
    repair: "Servizio",
    event: "Evento",
    data: "Dati",
    alarmTime: "Orario dell'allarme",
    picture: "Immagini",
    alarmType: "Tipo di allarme",
    powerDownTime: "Tempo di spegnimento",
    registerCode: "Codice dispositivo",
    whetherToRestore: "Stato di ripristino",
    fileName: "Spegnimento del gateway.xlsx",
    callRecord: "Registro delle chiamate",
    alarmFileName: "Campanello d'allarme.xlsx",
    callTime: "Tempo della chiamata",
    callDuration: "Durata della chiamata",
    originator: "Destinatario della chiamata",
    connector: "Destinatario",
    phone: "Numero di connessione",
    audio: "Audio",
    playAudio: "Riproduzione audio",
    faultType: "Tipo del guasto",
    faultTime: "Orario del guasto",
    faultNo: "Codice guasto",
    faultFloorCode: "Codice piano guasto",
    faultFloor: "Codice piano",
    displayFaultFloor: "Piano visualizzato",
    faultRecordFileName: "Registrazione dei guasti.xlsx",
    safetyHazardTime: "Tempo rischio sicurezza",
    safetyHazardCode: "Codice rischio sicurezza",
    safetyHazardDesc: "Descrizione rischio sicurezza",
    startFloor: "Piano di partenza",
    endFloor: "Piano di arrivo",
    runningDistance: "Distanza percorsa(m)",
    maxSpeed: "Velocità massima(m/s)",
    maxAcceleration: "Accelerazione massima(m/s2)",
    maxVariableAcceleration: "Accelerazione variabile massima (m/s3)",
    recordTime: "Tempo di registrazione",
    callElevatorStatistics: "Statistiche delle chiamate",
    username: "ID utente",
    registerMobile: "Numero di telefono registrato",
    implementationElevator: "Attiva ascensore",
    userLocation: "Posizione dell'utente",
    currentFloor: "Piano",
    destinationFloor: "Piano di destinazione",
    operationType: "Tipo di manovra",
    remark: "Note",
    externalCallUp: "Chiamata salita",
    externalCallDown: "Chiamata discesa",
    carCall: "Chiamata in auto",
    callElevatorTimes: "Conteggio delle chiamate",
    tip: {
      fillThreeDays: "Si prega di inserire l'orario dell'evento (entro tre giorni)",
      fillSevenDays: "Si prega di inserire l'orario di spegnimento (entro sette giorni)",
      fillFifteenDays: "Si prega di inserire l'orario entro 15 giorni!",
      fillMonthDays: "Si prega di compilare l'orario entro un mese！",
      alarmTime: "Si prega di inserire l'orario dell'allarme (entro sette giorni)",
      faultTime: "Si prega di inserire l'orario del guasto (entro due mesi)",
    },
  },

  // 电梯监控
  monitor: {
    change: "Cambiare mappe",
    mute: "Non parlare",
    area: "Area",
    escalatorMonitor: "Monitoraggio delle scale mobili",
    escalatorConnect: "Connessione in corso ...",
    error: "Commettere un errore",
    open: "Espandi",
    close: "Mettere via",
    buildNum: "Numero impianto",
    ladderNum: "Numero ascensore",
    runningSpeed: "Velocità",
    retry: "Riprovare",
    videoOnConnect: "Connessione. Attendere prego...",
    videoConnectFailed: "Connessione fallita, verificare se il plugin è installato...",
    a08Describe: "Timeout dei dati di monitoraggio",
    a10Describe: "Scheda madre offline",
    a11Describe: "Gateway offline",
    a06Describe: "L'ascensore non è stato trovato nella cache",
    a05Describe: "Numero di code",
    connectionDisconnection: "Disconnesso",
    currentUseUnit: "Quartiere attuale",
    elevatorMonitor: "Monitoraggio",
    onlineStatus: "Stato online",
    online: "Online",
    offline: "Offline",
    village: "Village name",
    monitoring: "Monitoraggio multilivello",
    monitor: "Più sorveglianza",
    monitorIBE: "ITE IOT",
    monitorA4Io: "Punti di ingresso e di uscita",
    information: "Informazioni chiave",
    liveData: "Dati in tempo reale dell'inverter",
    check: "Data della prossima ispezione annuale",
    maintenance: "Data della prossima manutenzione",
    transducer: "Serie Inverter",
    controlPanel: "Versione del software del pannello di controllo",
    unControlPanel: "Versione non standard del software della scheda di controllo",
    operationPanel: "Versione software del pannello operatore",
    customerNumber: "Numeri di serie personalizzati",
    selection: "Selezione del motore e del controllo",
    inverterStatus: "Stato dell'inverter",
    setFrequency: "Impostazione della frequenza",
    OutputFrequency: "Frequenza di uscita",
    OutputVoltage: "Tensione di uscita",
    OutputCurrent: "Corrente di uscita",
    DCBusVoltage: "Tensione del bus CC",
    PowerOnTimeAccumulation: "Accumulo del tempo di accensione",
    RunningTime: "Accumulo di tempo di esecuzione",
    FaultCode: "Codice di guasto dell'inverter",
    workingTime: "Tempo di funzionamento della scala mobile",
    faultTime: "Tempo di guasto",
    elevator: "Panoramica dell'ascensore",
    state: "Stato della serratura.",
    lock: "Serrattura",
    goUp: "Salita",
    goDown: "Discesa",
    record: "Record di funzionamento",
    faultCode:"Codice di errore verificatosi",
    speedPro:"Protezione da sovravelocità",
    nonPro:"Protezione contro l'inversione senza manipolazione",
    stepPro:"Protezione mancante per gradino o pedale",
    driveBorken:"Catena di trasmissione rotta o allungata",
    combBlock:"La piastra del pettine è bloccata da corpi estranei",
    stepSagg:"I gradini o i pedali cedono",
    emergStop:"Azione dell'interruttore di arresto di emergenza",
    status: {
      reConnection: "Riconnettersi",
      switchElevator: "Ascensori di commutazione",
      callUp: "Chiamata salita",
      callDown: "Chiamata discesa",
      carCall: "Chiamata dell'ascensore",
      faultReset: "Reset Del Guasto",
      open: "Apertura, Aperto",
      close: "Chiusura, Chiuso",
      motion: "Movimento",
      service: "Servizio",
      fireReturn: "Ripristino in caso di incendi",
      fire: "Vigile del fuoco",
      emergencyPower: "Alimentazione di emergenza",
      earthquakeModel: "Terremoto",
      unKnow: "Sconosciuto",
      safety: "Interruzione del circuito di sicurezza",
      fault: "Guasto",
      Lock: "Serrattura",
      independence: "Indipendente",
      driver: "Driver",
      parallelControl: "In parallelo/cluster",
      overload: "Sovraccarico",
      loaded: "Sovraccarico",
      serviceable: "Ascensore in servizio",
      mainPowerOff: "Sistema di alimentazione anomalo",
      openButton: "Pulsante di apertura",
      passengerStatus: "L'auto è occupata.",
      instructionSettings: "IstruzioniImpostazioni",
      touchpadActions: "Numero di azioni della piastra di sicurezza",
      curtainActions: "Numero di azioni della barriera fotoelettrica",
      lighting: "Numero di lampade accese nell'auto",
      lightingTime: "Tempo di accensione per l'illuminazione dell'auto",
      downwardRelevelingTimes: "Livelli di livellamento del lato superiore",
      upwardRelevelingTimes: "Livelli di livellamento verso il basso",
      runSpeed: "Velocità di corsa",
      runningDistance: "Distanza operativa dell'ascensore",
      stationLayer: "Numero di porte aperte a livello di stazione base",
      numberOfDoorOpenings: "Numero di aperture delle porte",
      IcCardAuthority: "Privilegi delle carte IC",
      setting: "Allestimento",
      networkControl: "Controllo della rete",
      remoteLockElevator: "Scala a bloccaggio remoto",
      currentSettings: "Leggere le impostazioni correnti",
      physicalFloor: "Pavimenti fisici",
      showFloor: "Piano espositivo",
      elevatorCarCommand: "Istruzioni in cabina",
      inExecution: "In fase di implementazione",
      allClosed: "Escluso",
      fullyOpen: "Completamente aperto",
      hallInstruction: "Direttive fuori ufficio",
      cancel: "Cancellazioni",
      saveSettings: "Salva le impostazioni",
      gateArea: "Area di sblocco",
      openDoorInPlace: "Con la porta aperta e in posizione",
      closeTheDoorInPlace: "Chiudere la porta in posizione",
      isAnyone: "C'è qualcuno sulla portantina",
      currentService: "Modello di servizio attuale sconosciuto",
      doorCircuit: "Circuito di blocco della porta",
      stopsOutside: "L'auto si ferma fuori dall'area di sblocco",
      duringOperation: "Aprire lo sportello durante il funzionamento",
      rushTop: "Corsa al vertice",
      squatBottom: "Squat sul fondo",
      speeding: "Eccesso di velocità",
      unexpectedMovement: "Movimento inatteso dei vagoni",
      safetyCircuitBreak: "Disconnessione del circuito di sicurezza",
      circuitMalfunction: "Guasto del circuito di chiusura della porta a pavimento",
      circuitFault: "Malfunzionamento del circuito di chiusura della portiera dell'auto",
      controlDevice: "Guasto ai comandi dell'ascensore",
      machineFailure: "Guasto alla macchina di trazione dell'ascensore",
      inverterFailure: "Guasto al convertitore di frequenza",
      systemFailure: "Guasto al sistema frenante di sollevamento",
      shortCircuit: "Dispositivo elettrico della serratura della porta in cortocircuito",
      levelingInductionFault: "Guasti di induzione a pavimento",
      floorLocationLost: "Posizione del pavimento persa",
      limiterAction: "Funzionamento del limitatore del tempo di funzionamento del motore",
      closeDoorFault: "Malfunzionamento di una porta",
      openDoorFault: "Problemi ad aprire la porta",
      emergencyStopFault: "Arresto di emergenza",
    },
    parameter: "Parametri operativi",
    parameters: {
      times: "Numero delle operazioni dell'ascensore",
      time: "Tempo di funzionamento dell'ascensore (h)",
      wire: "Numero di curve di scorrimento della fune metallica",
      openTimes: "N° di aperture e chiusure delle porte",
    },
    mark: {
      position: "Posizione attuale",
    },
    fault: {
      fault1: "Funzionamento normale",
      fault2: "Mancanza di difficoltà.",
      fault3: "Disconnesso e fuori contatto",
    },
    elevatorName: "Nome ascensore",
    alarmType: "Tipo di allarme",
    continueTime: "Durata",
    operation: "Operazione",
    elevatorStop: "Non disponibile",
    elevatorStranded: "Bloccato",
    alarmBellsAlarm: "Campanello d'allarme",
    tip: {
      confirmReset: "Sei sicuro di aver eseguito il reset?",
      faultResetFail: "Guasto di reset del guasto",
      faultResetSuccess: "Ripristino dei guasti riuscito",
    },
  },

  mb: {
    changeType: "Contenuto dell'operazione",
    changeTime: "Tempo di funzionamento",
    eventName: "Evento",
  },

  userLoginLog: {
    userName: "ID utente",
    name: "Nome dipendente",
    startTime: "Ora di inizio",
    endTime: "Ora di fine",
    loginType: "Tipo di accesso ",
    loginIp: "IP di accesso ",
    loginTime: "Tempo di accesso",
    web: "Web",
    app: "APP",
    wx: "Applet WeChat",
  },

  //统计报告
  statsReport: {
    report: "Rapporti statistici",
    positions: "Posizione attuale > Rapporti statistici",
    cycle: "Ciclo statistico",
    pdf: "Stampa PDF",
    info: "Informazioni Ascensore",
    name: "Nome ascensore",
    no: "N° Ascensore",
    type: "Tipo di ascensore",
    code: "Codice dispositivo",
    number: "Numero di fabbrica",
    unit: "Identificazione edificio installazione",
    companyInfo: "Informazioni Azienda",
    madeCompany: "Azienda produttrice",
    maintCompany: "Azienda di manutenzione",
    worker1Name: "Addetto alla manutenzione1 Nome",
    worker1Phone: "Addetto alla manutenzione1 Telefono",
    worker2Name: "Addetto alla manutenzione2 Nome",
    worker2Phone: "Addetto alla manutenzione2 Telefono",
    rate1: "Mancato arresto del tasso di scala:",
    rate2: "Tasso di intrappolamento dei guasti:",
    time1: "Tempi di inattività per la manutenzione:",
    time2: "Tempo di arresto errato:",
    time3: "Tempo di risposta dei soccorsi:",
    time4: "Tempo di salvataggio:",
    percent: "Percentuale",
    faultPercnet: "percentuale di guasti",
    month: "il mese corrente",
    sixMonth: "Ultimi sei mesi",
    year: "Quest'anno fino ad oggi",
  },

  vibrationData: {
    speedMax:"velocitàMax",
    speedMin:"velocitàMin",
    scanCheck:"Si prega di utilizzare l'APP Elevator Cloud per scansionare il codice da visualizzare",
    moreVib:"Visualizza altre curve correlate",
    idNo: "Numero ID",
    elevatorName: "Nome ascensore",
    createTime: "Tempo di creazione",
    runState: "Modalità",
    dataDesc: "Note",
    downLoad: "Scaricare",
    curve: "Curva",
    tab1: "Elenco dei download",
    tab2: "Operatore",
    collectTime: "Tempo di campionamento",
    collectFrequency: "Frequenza di campionamento",
    collectType: "Tipo di raccolta",
    calibration: "Calibrazioni",
    eocdParamsSet: "Parametrizzazione",
    searchEOCDData: "Interrogare i dati EOCD",
    startCollect: "Avvio manuale dell'acquisizione",
    endCollect: "Interrompere manualmente l'acquisizione",
    clearCollect: "Svuotare il record di raccolta",
    cancelCollect: "Ritiro della raccolta",
    parTotalSwitch: "Interruttore principale EOCD sul tetto dell'auto",
    parCollectSwitch: "Interruttore di acquisizione automatica EOCD sul tetto dell'auto",
    parCollectCycle: "Ciclo di acquisizione automatico",
    parCollectFrequency: "Frequenza di acquisizione automatica",
    acquisitionParameters: "Parametri di acquisizione",
    autoUpload: "Caricamento automatico",
    doorCollectSwitch: "Interruttore di acquisizione automatica per l'apertura e la chiusura della porta",
    doorCollectCycle: "Apertura e chiusura della porta Ciclo di acquisizione automatica",
    alarmFileSwitch: "Caricamento automatico dei file di allarme",
    safetyHazardSwitch: "Interruttore di allarme di sicurezza",
    safetyHazardRecord: "Registrazione dei dati degli allarmi di sicurezza",
    read: "Recuperare",
    set: "Allestimento",
    dtuNoOnline: "Il terminale non è online e non supporta questa operazione",
  },

  newMsgSend: {
    referBizNo: "Numero ordine associato",
    type: "Tipo di messaggio",
    msgPush: "Invio messaggio APP",
    sms: "SMS",
    email: "Email",
    wxMsgPush: "Invio messaggio applet",
    content: "Contatto messaggio",
    target: "Cliente di riferimento",
    status: "Stato del mittente",
    sendSucceed: "Inviato con successo",
    failSend: "Invio fallito",
    sendTime: "Invio tempi",
    notSend: "Non invio",
  },

  uiSetting: {
    currentSetting: "Impostazione attuale",
    systemName: "Nome del sistema",
    logoLarge: "Logo grande",
    logoSmall: "Logo piccolo",
    banner: "Slideshow dell'home page",
    appBanner: "Slideshow dell'app ",
    companyName: "Aziende",
    faviconIcon: "Icona della barra del titolo",
    tip: {
      fileType: "Possono essere caricate solo immagini in formato jpg e png!",
      isLt2M: "Le dimensioni dell'immagine non devono superare i 2MB!",
      pictures: "Superare il numero di immagini, cancellare prima di aggiungere",
    },
  },

  role: {
    role: "Ruolo",
    auth: "Elenco delle autorizzazioni",
    name: "Nome",
    isDefault: "Ruolo predefinito",
    orgName: "Nome Azienda",
    tip: {
      name: "Il nome del carattere non può essere vuoto",
    },
  },

  parts: {
    bindingRecord: "绑定记录",
    no: "Numero",
    name: "Nome",
    partsTypeName: "Tipo di accessorio",
    elevatorCompanyName: "Azienda di ascensori",
    partsSn: "Eid accessorio",
    model: "Modello",
    brand: "Marca",
    price: "Prezzi",
    times: "Numero di utilizzi",
    life: "Scadenza",
    status: "Stato dell'autenticazione",
    creatorName: "Creatore",
    elevatorProductDescription: "Note",
    hot: "È di tendenza?",
    tip: {
      no: "Il numero non può essere vuoto",
      name: "Il nome non può essere vuoto",
      partsSn: "L'ID del componente deve essere di 34 caratteri",
    },
    result: {
      band: "Associato ",
      noBand: "Svincolato ",
    },
  },

  userOperateLog: {
    operateTime: "Ora dell'operazione",
    userName: "Nome utente",
    name: "Nome",
    startTime: "Orario di inizio",
    endTime: "Orario di fine",
    operateContent: "Contenuto dell'operazione",
    operateLog: "Log operazioni",
    appOperateLog: "APP log operazioni",
  },

  headerBar: {
    app: "Scarica l'APP",
    unavailable: "Videosorveglianza non attivata",
    connect: "Connessione in corso...",
    nonsupport: "Ambiente corrente non supportato",
    connectionFailed: "Interruzione della connessione alla videosorveglianza",
    available: "La videosorveglianza funziona",
    newConnection: "C'è una nuova richiesta di videosorveglianza",
    landed: "Altri luoghi di accesso",
    setting: "Impostazioni dell'account",
    logout: "Disconnessione",
    uiSetting: "Impostazioni dell'interfaccia",
    globalMute: "Silenzia tutto",
    cancelMute: "Annulla silenziamento",
    mute: "Silenzia",
    board: "Cruscotto",
    serial: "Autorizzazione porta seriale",
    recentMaintWork:
      "I lavori di manutenzione dei seguenti ascensori stanno per scadere",
    recentContract: "I seguenti contratti stanno per scadere",
    recentYearCheck:
      "L'ispezione annuale dei seguenti ascensori scadrà presto",
  },
};
